.error{
    font-size: 16px;
    color: #B50918;

}

.success{
    font-size: 16px;
    color: #009400;
}

.msg-container{
    width: 100%;

    .msg-body{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid rgba(128, 128, 128, 0.267);
        padding-top: 20px;
        padding-bottom: 20px;

        :hover {
            
            .msg-text{
                color: #2897FB;
            }
        }

        .msg-text-container{

            display: flex;
            flex-direction: row;

            :first-child{
                margin-top: 2px;
            }

            .msg-text{
                font-weight: bold;
                padding-bottom: 7px;
            }
        
        }
    }
    
}

.msg-single-container{
    border-top: 1px solid rgba(128, 128, 128, 0.267);
    width: 100%;
    padding: 30px 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    .msg-btn{
        background:  linear-gradient(#2999FF, #03254B);
        color: white;
        text-transform: uppercase;
        padding: 15px 50px;
        border-radius:10px;
        font-weight: 500;
        float: right;
    }

    .response-msg{

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .msg-input-wrapper{
            width: 45%;
            margin: 10px 0px;
    
            label{
                font-weight: 500;
                font-size: 20px;
                color: #6B6B6B;
            }
    
            input{
               margin-top: 10px;
            }
    
            textarea{
                margin-top: 10px;
            }
        }
    }
    
}