@import "../../../scss/colors";

.cv-phoneinput-prefix {
  color: $secondary;
  margin-right: 5px;
}

.cv-phoneinput-main {
  display: inline;
  position: relative;
}

.cv-phoneinput-icon {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
