#mainContent {
  .title-buttons {
    display: flex;
  }
  .new-ads {
    display: flex;
    flex-direction: column;

    .title-box {
      width: 100%;
      height: max-content;
      padding: 20px;
      margin-bottom: 20px;
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;

      button {
        height: 30px;
        width: 30px;
        color: rgba($color: #000000, $alpha: 0.2);

        svg {
          height: 80%;
        }

        &:hover,
        &:focus {
          color: $bluel;
        }
      }
    }
    .ads-box-wrapper {
      width: calc(100% - 20px);
      height: 120px;
      flex: 1 auto;
      margin-top: auto;
      margin-bottom: 20px;
      overflow-x: hidden;
      overflow-y: scroll;
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .rad-box {
      width: 100%;
      min-height: 60px;
      display: flex;
      padding: 5px 20px;
      margin-bottom: 0px;
      text-align: left;
      position: relative;
      overflow: hidden;

      .company-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 1px solid rgba($color: #000000, $alpha: 0.2);
        padding: 5px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: center;
        }
      }

      .company-desc {
        flex: 1;
        padding: 5px 10px;
        h3 {
          color: #000;
          font-size: 14px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        p {
          font-size: 11px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #888;
        }
      }

      .company-created-at {
        width: min-content;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 11px;
        color: #888;
        padding: 5px 0px;
      }

      // h3 {
      //   display: block;
      //   width: 100%;
      //   text-overflow: ellipsis;
      //   white-space: nowrap;
      //   overflow: hidden;
      //   transition: 0.2s ease-in-out;
      // }

      // p {
      //   font-size: 15px;
      //   font-weight: 300;
      //   color: $grayl;
      // }

      // .logo-box {
      //   width: 60px;
      //   height: 60px;
      //   margin-right: 10px;
      //   border-radius: 50%;
      //   position: relative;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   background: white;
      //   border: solid 1px rgba($color: #000000, $alpha: 0.2);
      //   overflow: hidden;
      //   transition: 0.2s ease-in-out;

      //   img {
      //     width: 100%;
      //     height: 100%;
      //     object-position: center;
      //     object-fit: contain;

      //   }
      // }
      // .text-wrap {
      //   width: calc(100% - 70px);
      //   height: max-content;
      //   display: flex;
      //   flex-wrap: wrap;
      //   h3 {
      //     @include func(font-size, 18, 16);
      //     width: 68%;
      //     margin-right: 2%;
      //   }
      //   p {
      //     word-break: normal !important;
      //     &.created {
      //       width: 30%;
      //     }
      //     &.location {
      //       width: 100%;
      //       text-transform: uppercase;
      //     }
      //   }
      //   @include mq("sm") {
      //     h3 {
      //       width: 100%;
      //       margin-right: 0;
      //     }
      //     p {
      //       &.created {
      //         width: 100%;
      //         margin-bottom: 5px;
      //       }
      //     }
      //   }
      // }

      // &:hover,
      // &:focus {
      //   h3 {
      //     color: $bluel;
      //   }
      //   .logo-box {
      //     border-color: $bluel;
      //   }
      // }
    }

    .details {
      text-align: left;
      font-size: 15px;
      text-transform: uppercase;
      color: $bluel;
      font-weight: 500;
      padding: 20px;
      margin-top: auto;

      &:hover,
      &:focus {
        color: $blued;
      }
    }

    @include mq('xl-16') {
      .title-box {
        padding: 15px;
        margin-bottom: 15px;
        h2 {
          font-size: 18px;
        }
      }
      .rad-box {
        h3 {
          font-size: 18px;
        }
      }
      .details {
        padding: 15px;
      }
    }

    @include mq('xl') {
      .title-box {
        h2 {
          @include func(font-size, 22, 16);
        }
      }
      .rad-box {
        h3 {
          @include func(font-size, 22, 16);
        }
      }
    }
  }
  .video-box {
    // max-height: 240px;
    overflow: hidden;

    video {
      width: 100%;
      height: 100%;
      //filter: brightness(0.5);
      object-fit: cover;
      object-position: center;
    }

    .add-video {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 30px;
      width: 30px;
      color: white;

      svg {
        height: 25px;
        transition: 0.2s ease-in-out;
      }

      &:hover,
      &:focus {
        svg {
          transform: scale(1.1);
        }
      }

      &:active {
        svg {
          transform: scale(1);
        }
      }
    }

    .play-video {
      height: max-content;
      width: max-content;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      svg {
        height: 50px;
        transition: 0.2s ease-in-out;

        path {
          fill: url('#yellowGradient');
        }
      }

      &:hover,
      &:focus {
        svg {
          transform: scale(1.2);
        }
      }

      &:active {
        svg {
          transform: scale(1);
        }
      }
    }
  }
  .cv-application {
    display: flex;

    .ico-side {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100px;
      border-right: solid 1px rgba($color: #000000, $alpha: 0.2);

      svg {
        width: 40px;

        &.height-50 {
          height: 50px !important;
          width: auto;
        }

        path {
          fill: url('#blueGradient');
        }
      }
    }

    .stats-side {
      height: 100%;
      width: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      position: relative;
      margin-top: auto;

      h5 {
        color: $grayl;
        font-weight: 500;
        @include func(font-size, 18, 16);
      }

      .big {
        @include func(font-size, 60, 40);
        font-weight: 500;
        line-height: 1.1em;
        position: relative;
        color: $bluel;
        margin: 20px 0;
      }

      button {
        width: 100%;
      }
    }

    @include mq('xl-16') {
      flex-wrap: wrap;

      .ico-side {
        width: 100%;
        height: max-content;
        padding: 15px;
        border-right: none;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        svg {
          width: 35px;
          &.height-50 {
            height: 40px !important;
          }
        }
      }

      .stats-side {
        width: 100%;
        height: max-content;
        padding: 15px;

        h5,
        h6 {
          text-align: center;
          font-size: 16px;
        }

        .big {
          @include func(font-size, 50, 30);
          margin: 15px 0;
        }
      }
    }

    @include mq('xl-14') {
      .ico-side {
        svg {
          width: 30px;
          &.height-50 {
            height: 37.5px !important;
          }
        }
      }
    }

    @include mq('sm') {
      min-height: max-content;

      .ico-side {
        width: 100px;
        height: auto;
        border-bottom: none;
        border-right: solid 1px rgba($color: #000000, $alpha: 0.2);
        padding: 0;

        svg {
          width: 30px;

          &.height-50 {
            height: 37.5px !important;
          }
        }
      }

      .stats-side {
        width: calc(100% - 100px);
        height: max-content;
      }
    }

    @include mq('sm-4') {
      .stats-side {
        .big {
          margin-bottom: 0;
        }
      }
    }
  }
  .stats-box {
    display: flex;
    flex-direction: column;
    position: relative;

    .title-box {
      width: 100%;
      height: max-content;
      padding: 20px;
      margin-bottom: 20px;
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;
      h2 {
        margin-right: 20px;
      }

      @include mq('sm-4') {
        h2 {
          text-align: center;
        }
      }
    }

    .select-wrapper {
      position: relative;
      width: max-content;
      height: max-content;

      .select-arrow {
        width: 10px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: $grayl;
        transition: 0.2s ease-in-out;
      }
      #year {
        @include prefixer(appearance, none);
        outline: none;
        border: none;
        padding: 10px 15px 10px 0;
        font-size: 15px;
        color: $grayl;
        cursor: pointer;
        transition: 0.2s ease-in-out;
      }
      &:hover,
      &:focus {
        #year {
          color: $bluel;
        }

        .select-arrow {
          color: $bluel;
        }
      }

      @include mq('sm-4') {
        display: none;
      }
    }

    .mobile-select {
      width: 100%;
      height: max-content;
      display: none;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;

      button {
        width: 30%;
        padding: 10px;
        font-size: 14px;
        color: $grayl;
        font-weight: 300;
        border-radius: 10px;

        &:hover,
        &:focus,
        &.active {
          box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
          color: $bluel;
        }
      }

      @include mq('sm-4') {
        display: flex;
      }
    }

    .yearly-stats-box {
      display: flex;
      flex-direction: row;
      margin-right: 5px;
      flex: 1;
      .yearly-stats-box-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        flex-direction: column;
      }
      &:last-child {
        margin-right: 0px;
      }

      .mounth {
        height: max-content;
        padding: 30px 0;
        position: relative;
        .number {
          @include func(font-size, 18, 15);
          font-weight: 500;
          color: $grayd;
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        .mounth-name {
          @include func(font-size, 17, 14);
          text-transform: uppercase;
          font-weight: 300;
          color: $grayd;
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }

        .bar-height {
          height: 60px;
          width: 100%;
          position: relative;
          display: flex;
          align-items: flex-end;

          .bar-fill {
            background: linear-gradient(to top, $blued, $bluel);
            border-radius: 7px;
            width: 100%;
            max-height: 100%;
            min-height: 10px;
          }

          .bar-empty {
            background: linear-gradient(to top, $yellowd, $yellowl);
            border-radius: 7px;
            width: 100%;
            max-height: 100%;
            height: 10px !important;
          }
        }
      }
    }

    @include mq('xl-16') {
      .title-box {
        padding: 15px;
        h2 {
          margin-right: 15px;
          font-size: 18px;
        }
      }
    }
    @include mq('xl') {
      .title-box {
        h2 {
          @include func(font-size, 22, 16);
        }
      }
    }

    @include mq('sm-4') {
      .title-box {
        h2 {
          width: 100%;
        }
      }

      .select-wrapper {
        #year {
          padding-left: 0;
        }
      }
    }
  }
  .profile-fill-status {
    display: flex;
    align-items: center;
    padding: 20px;

    .ico-side-mobile {
      display: none;

      svg {
        width: 40px;
      }
    }

    .left-side {
      width: calc(100% - 170px);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      button {
        width: 100%;
      }
    }

    h5 {
      font-weight: 500;
      @include func(font-size, 18, 16);
      color: $grayl;
    }

    .counter-wrapper {
      width: max-content;
      height: max-content;
      position: relative;
      z-index: 3;

      .precent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include func(font-size, 48, 24);
        font-weight: 500;
        color: $bluel;
      }

      #counterBg {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
      }
    }

    .show-xl-16 {
      display: none;
    }

    @include mq('xl-16') {
      flex-wrap: wrap;
      justify-content: center;
      padding: 15px;

      h5 {
        width: 100%;
        text-align: center;
        margin-bottom: 20px;
        font-size: 16px;
      }

      button {
        width: 100%;
      }

      .left-side {
        width: 100%;
        height: max-content;
      }

      .hide-xl-16 {
        display: none;
      }

      .show-xl-16 {
        display: flex;
      }

      .counter-wrapper {
        margin-bottom: 20px;
      }

      canvas {
        width: 150px;
        height: 150px;
      }
    }

    @include mq('xl-14') {
      canvas {
        width: 130px;
        height: 130px;
      }
    }

    @include mq('sm') {
      padding: 0;
      flex-wrap: wrap;
      height: max-content;
      justify-content: center;

      button {
        margin: 15px;
      }

      .left-side {
        width: max-content;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      h5 {
        margin-top: 20px;
        margin-left: 20px;
      }

      .ico-side-mobile {
        display: flex;
        width: 100%;
        height: max-content;
        align-items: center;
        justify-content: center;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        padding: 15px;
      }

      .counter-wrapper {
        margin: 20px 20px 20px 20px;

        canvas {
          width: 150px;
          height: 150px;
        }
      }
    }

    @include mq('sm-4') {
      .left-side {
        width: 100%;
        margin: 15px;
      }
      h5 {
        margin: 0;
      }
    }
  }
  .recent-applications-box {
    position: relative;
    display: flex;

    .left-side {
      width: 160px;
      height: auto;
      padding: 20px;
      border-right: solid 1px rgba($color: #000000, $alpha: 0.2);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      svg {
        height: 50px;
        width: 50px;
        margin-bottom: 20px;

        path {
          fill: url('#blueGradient');
        }
      }

      h2 {
        margin-bottom: 40px;
      }

      h5 {
        color: $grayl;
        font-weight: 300;
      }
    }

    .right-side {
      height: max-content;
      width: calc(100% - 160px);
      padding: 20px;
      position: relative;
      display: flex;
      flex-direction: column;

      .row {
        width: 100%;
        height: max-content;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        display: flex;
        position: relative;

        // fonts
        .row-title {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: normal;
          color: $bluel;
        }

        .bold {
          font-weight: 500;
          color: $grayl !important;
          font-size: 14px;
          transition: 0.2s ease-in-out;
        }

        .light {
          font-weight: 300;
          color: $grayl !important;
          font-size: 14px;
        }

        // columns
        .column {
          padding: 15px 0;
          height: max-content;
          display: flex;
          align-items: center;
          color: $bluel;

          svg {
            width: 20px;
            cursor: pointer;
            transition: 0.2s ease-in-out;

            &.disabled {
              color: $grayl;
            }
          }
        }

        .l-column {
          width: 35%;
          padding-right: 10px;
        }

        .m-column {
          width: 20%;
          padding-right: 10px;
        }

        .s-column {
          width: 10%;
          display: flex;
          justify-content: flex-end;
        }

        &:hover,
        &:focus {
          .bold {
            color: $bluel !important;
          }
        }

        &:nth-last-child(1) {
          border-bottom: none;

          .column {
            padding-bottom: 0;
          }
        }
      }
    }

    .right-side-mobile {
      display: none;
    }

    @include mq('xl-16') {
      .left-side {
        h2 {
          font-size: 18px;
        }
      }
    }
    @include mq('xl') {
      .left-side {
        h2 {
          @include func(font-size, 22, 16);
        }
      }
    }

    @include mq('md') {
      flex-wrap: wrap;

      .left-side {
        width: 100%;
        border-right: none;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;

        svg {
          margin-bottom: 0;
          margin-right: 20px;
        }

        h2 {
          margin-bottom: 0;
          width: calc(100% - 70px);
        }

        h5 {
          width: 100%;
          margin-top: 20px;
        }
      }

      .right-side {
        width: 100%;
      }
    }

    @include mq('sm') {
      .right-side {
        display: none;
      }

      .right-side-mobile {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: max-content;
        position: relative;
        padding: 20px;

        p {
          width: 49%;
          font-weight: 300;
          color: $grayl;
          font-size: 14px;
          word-break: break-all;

          &.bold {
            font-weight: 500;
            color: $grayl;
            font-size: 14px;
            transition: 0.2s ease-in-out;
          }

          &.title {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: 500;
            color: $bluel;
          }
        }

        button {
          width: 49%;
          display: flex;
          align-items: flex-start;
          color: $bluel;
        }

        svg {
          width: 20px;
          cursor: pointer;
          transition: 0.2s ease-in-out;

          &.disabled {
            color: $grayl;
          }
        }

        .mobile-row {
          width: 100%;
          height: max-content;
          display: flex;
          justify-content: space-between;
          position: relative;
          margin-bottom: 20px;
        }

        details {
          width: 100%;
          overflow: hidden;
          border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);

          &[open] {
            padding-bottom: 20px;
          }

          &:hover,
          &:focus {
            .bold {
              color: $bluel;
            }
          }
        }

        summary {
          width: 100%;
          display: flex;
          justify-content: space-between;
          height: max-content;
          position: relative;
          cursor: pointer;
          padding: 20px 0;
          outline: none;
          border: none;

          &::-webkit-details-marker {
            display: none;
          }
        }
      }
    }

    @include mq('sm-4') {
      .left-side {
        h2 {
          width: 100%;
          text-align: center;
        }

        h5 {
          width: 100%;
          text-align: center;
          margin-top: 5px;
        }
      }
    }
  }

  .new-candidates-box {
    position: relative;
    display: flex;

    .left-side {
      width: 200px;
      height: auto;
      padding: 20px;
      border-right: solid 1px rgba($color: #000000, $alpha: 0.2);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      svg {
        height: 50px;
        width: 60px;
        margin-bottom: 20px;

        path {
          fill: url('#blueGradient');
        }
      }

      h2 {
        margin-bottom: 40px;
      }
      h5 {
        color: $grayl;
        font-weight: 300;
      }
    }
    .right-side {
      height: max-content;
      width: calc(100% - 200px);
      padding: 20px;
      position: relative;
      display: flex;
      flex-direction: column;

      .row {
        width: 100%;
        height: max-content;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        display: flex;
        position: relative;

        // fonts
        .row-title {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: normal;
          color: $bluel;
        }
        .bold {
          font-weight: 500;
          color: $grayl !important;
          font-size: 14px;
          transition: 0.2s ease-in-out;
        }
        .light {
          font-weight: 300;
          color: $grayl !important;
          font-size: 14px;
        }

        // columns
        .column {
          padding: 15px 0;
          height: auto;
          display: flex;
          align-items: center;
          color: $bluel;

          svg {
            width: 20px;
            cursor: pointer;
            transition: 0.2s ease-in-out;

            &.disabled {
              color: $grayl;
            }
          }
        }

        .l-column {
          width: 30%;
          padding-right: 10px;
        }
        .m-column {
          width: 25%;
          padding-right: 10px;
        }
        .s-column {
          width: 10%;
          display: flex;
          justify-content: flex-end;
        }
        .hvr-link {
          justify-content: flex-end;
          padding-right: 0;

          svg {
            width: 0;
            margin-left: 0;
            transition: 0.2s ease-in-out;
            @include prefixer(pointer-events, none);
          }

          &:hover,
          &:focus {
            svg {
              width: 14px;
              margin-left: 15px;
            }
          }
        }

        &:hover,
        &:focus {
          .bold {
            color: $bluel !important;
          }
        }

        &:nth-last-child(1) {
          border-bottom: none;

          //   .column {
          //     padding-bottom: 0;
          //   }
        }
      }
    }

    @include mq('xl') {
      .left-side {
        width: 180px;
      }
    }
    @include mq('md') {
      flex-wrap: wrap;

      .left-side {
        width: 100%;
        border-right: none;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;

        svg {
          margin-right: 20px;
        }

        h2 {
          margin-bottom: 0;
          width: calc(100% - 70px);
        }

        h5 {
          width: 100%;
          margin-top: 20px;
        }
      }

      .right-side {
        width: 100%;
      }
    }
    @include mq('sm') {
      .left-side {
        svg {
          display: none;
        }

        h2 {
          width: 100%;
          text-align: center;
        }

        h5 {
          width: 100%;
          text-align: center;
          margin-top: 5px;
        }
      }
      .right-side {
        display: none;
      }
    }
    @include mq('sm-4') {
      .left-side {
        h2 {
          width: 100%;
          text-align: center;
        }

        h5 {
          width: 100%;
          text-align: center;
          margin-top: 5px;
        }
      }
    }
  }
  .admin-table {
    @include mq('xl-14') {
      flex-wrap: wrap;

      .left-side {
        width: 100%;
        height: max-content;
        border-right: none;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        margin-bottom: -20px;
        position: relative;
        padding-left: 90px;

        svg {
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%);
          width: 50px;
        }

        h2 {
          margin-bottom: 20px;
        }
      }

      .right-side {
        width: 100%;
      }
    }

    @include mq('xl') {
      .right-side {
        .hvr-link {
          svg {
            display: none;
          }
        }
      }
    }

    @include mq('md') {
      .left-side {
        margin-bottom: 20px;

        h5 {
          margin-top: 0;
        }
      }

      .right-side {
        display: none;
      }

      .mobile-carousel {
        display: initial;
      }
    }

    @include mq('sm') {
      .left-side {
        padding-left: 20px;
      }
    }
  }
  .mobile-carousel {
    display: none;
    width: 100%;
    height: max-content;
    position: relative;

    .item {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      padding: 20px 50px;

      p {
        color: $bluel;
        margin-bottom: 5px;
        text-align: center;
      }

      a {
        font-weight: 500;
        font-size: 20px;
        margin-bottom: 20px;
        text-align: center;
        color: $grayl;

        &:hover,
        &:focus {
          color: $bluel;
        }
      }

      h5 {
        font-weight: 300;
        font-size: 18px;
        margin-bottom: 20px;
        text-align: center;
        color: $grayl;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
    .table-carousel-nav {
      width: 100%;
      height: max-content;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      outline: none;
      border: none;
      padding: 0 10px;

      button {
        height: 40px;
        width: 40px;
        color: white;
        background: rgba($color: #000000, $alpha: 0.2);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 20px;
        }

        &:hover,
        &:focus {
          background: $bluel;
        }
      }
    }
    @include mq('sm') {
      display: initial;
    }
  }

  button {
    &.disabled {
      color: $grayl !important;
    }
  }

  .add-scroll {
    .right-side {
      height: 330px;
      overflow-y: auto;
      .stats-row {
        transition: 0.2s ease-in-out;

        &:focus {
          background: rgba(40, 151, 251, 0.1);
        }

        :first-child {
          cursor: pointer;
        }
      }
    }
  }

  @include mq('sm') {
    .new-order-1 {
      order: 1;
    }

    .new-order-2 {
      order: 2;
    }

    .new-order-3 {
      order: 3;
    }

    .new-order-4 {
      order: 4;
    }

    .new-order-5 {
      order: 5;
    }

    .new-order-6 {
      order: 6;
    }

    .new-order-7 {
      order: 7;
    }
  }
  @include mq('sm-4') {
    .new-ads {
      order: 2;
    }

    .stats-box {
      order: 3;
    }

    .cv-docs {
      order: 4;
    }

    .cv-application {
      order: 5;
    }

    .video-box {
      order: 6;
    }

    .profile-fill-status {
      order: 7;
    }

    .recent-applications-box {
      order: 8;
    }
  }
}

// admin & comapny homepage
.company-s-container {
  display: flex;

  .ico-side {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100px;
    border-right: solid 1px rgba($color: #000000, $alpha: 0.2);

    svg {
      height: 50px;
      width: 60px;

      path {
        fill: url('#blueGradient');
      }
    }
  }

  .stats-side {
    height: 100%;
    width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    position: relative;

    h5 {
      color: $grayl;
      font-weight: 500;
      @include func(font-size, 18, 16);
    }

    .big {
      @include func(font-size, 60, 40);
      font-weight: 500;
      line-height: 1.1em;
      position: relative;
      color: $bluel;
      margin: 20px 0;
    }

    button {
      width: 100%;
    }
  }

  @include mq('xl-16') {
    flex-wrap: wrap;

    .ico-side {
      width: 100%;
      height: max-content;
      padding: 15px;
      border-right: none;
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);

      svg {
        width: 35px;
      }
    }

    .stats-side {
      width: 100%;
      height: max-content;
      margin-top: auto;
      padding: 15px;

      h5,
      h6 {
        text-align: center;
        font-size: 16px;
      }

      .big {
        @include func(font-size, 50, 30);
        margin: 15px 0;
      }

      h5,
      h6,
      .application-number {
        text-align: center;
      }
    }
  }

  @include mq('sm') {
    // height: max-content;
    min-height: max-content !important;
    .ico-side {
      width: 100px;
      height: auto;
      border-bottom: none;
      border-right: solid 1px rgba($color: #000000, $alpha: 0.2);
      padding: 0;
    }

    .stats-side {
      width: calc(100% - 100px);
      height: max-content;
    }
  }

  @include mq('sm-4') {
    min-height: max-content;

    .ico-side {
      width: 80px;
      height: auto;

      svg {
        width: 40px;
      }
    }

    .stats-side {
      height: max-content;
      width: calc(100% - 80px);

      .big {
        margin-bottom: 0;
      }

      .application-number {
        // font-size: 13px;
        // position: absolute;
        // right: 20px;
        // bottom: 20px;
        // text-align: end;
        // width: calc(100% - 100px);
        margin-top: 20px;
      }
    }
  }
}

.box-container {
  height: auto;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
  position: relative;
  margin-bottom: 30px;
}
.l-container {
  width: 100%;
}
.m-container {
  width: 48%;

  @include mq('xl') {
    width: 100%;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}
.s-container {
  width: 24%;
  min-height: 200px;

  @include mq('xl') {
    width: 48%;
  }

  @include mq('sm') {
    width: 100%;
  }
}

.calendar-wrapper {
  padding: 0px 10px;
  width: 100%;
  height: max-content;
  position: relative;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex: 1;
  justify-content: space-around;

  .calendar-nav {
    display: none !important;
  }

  @media (max-width: 750px) {
    .calendar-nav {
      display: inherit !important;
    }
  }

  @include mq('xl-16') {
    padding: 0 10px;
  }

  @include mq('xl') {
    padding: 0;
  }

  @include mq('md') {
    padding: 0 60px;
  }
}

.calendar-carousel-nav {
  width: 100%;
  height: max-content;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  padding: 0 10px;
  outline: none;
  border: none;

  button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: rgba($color: #000000, $alpha: 0.2);

    svg {
      height: 50%;
    }

    &:hover,
    &:focus {
      background: $bluel;
    }
  }
}

.mounths-wrapper {
  height: max-content;
  position: relative;
  padding: 0 10px 20px 10px;

  @include mq('sm-4') {
    padding: 0 7px 20px 7px;
  }
}

#mainContent .concourse-table-lg thead {
  width: 100%;
  display: block;
}

.comp-desc {

  p {
    font-size: calc( 14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))) !important;
    font-family: 'Rubik', sans-serif !important;
    color: #6b6b6b !important;
  }

  @media only screen and (min-width: 1920px) {
    p {
      font-size: 16px !important;
    }
  }
 
}



#mainContent .add-scroll .right-side.status-mob {
  display: none;
}


@media only screen and (max-width: 768px) {

  #mainContent .new-candidates-box.new-candidates-box-mob,
  #mainContent .recent-applications-box.recent-applications-box-mob {
    background: transparent;
    box-shadow: none;
    border-radius: 0;   
  }

  #mainContent .new-candidates-box .left-side.left-side-mob,
  #mainContent .recent-applications-box .left-side.left-side-mob {
    border-bottom: none;
    justify-content: center;
    padding-top: 0;
    margin-bottom: 0;
    padding-left: 20px;

    h2 {
      font-size: 18px !important;
      margin-bottom: 5px;
      width: 100%;
      text-align: center;
    }

    h5 {
      width: 100%;
      text-align: center;
    }
  }

  #mainContent .new-candidates-box .left-side.left-side-mob {
    svg {
      margin-right: 0;
      display: none;
    }
  }

  #mainContent .add-scroll .right-side.status-mob {
    display: flex;
    height: auto;
    overflow-x: auto;
    overflow-y: initial;
    margin-bottom: 0;
    flex-direction: row;
    width: 100%;
    padding: 0;
    background: linear-gradient(#ffffff, #eff3f6);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);

    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;

    .stats-row {
      display: flex;
      flex-direction: column;
      border-bottom: none;
      padding: 15px;
      min-width: 100%;

      scroll-snap-align: start;

      .stats-item {
        padding-bottom: 15px;

        .column {
          padding: 0;
          width: 100%;
        }
      }

      .stats-item:last-child {
        padding-bottom: 0;
      }
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  #mainContent .add-scroll .right-side.status-mob::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  #mainContent .add-scroll .right-side.status-mob {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  #mainContent .add-scroll .right-side.status-mob .stats-row .stats-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  #mainContent .add-scroll .right-side.status-mob .stats-row .stats-item .column {
    width: auto;
  }
}

.swipe-wrapper {
  position: relative;
  width: 100%;
  align-items: center;
  margin-top: -15px;
  margin-bottom: 30px;
  display: none;
}

.swipe-wrapper .swipe {
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.swipe-wrapper .swipe svg {
  height: 18px;
  width: 18px;
  margin-right: 10px;
}

.swipe-wrapper .swipe svg path {
  fill: rgba(0, 0, 0, 0.4);
}

.swipe-wrapper .line {
  height: 1px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.swipe-wrapper .swipe span {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.4);
}

.swipe-wrapper-status-mob {
  order: 9;
  margin-bottom: 15px;
}

nav.xl-resize a.profile-mobile {
  display: none;
}

.nav-admin-wrapper {
  position: relative;
  z-index: 2;
}

main.main-js {
  z-index: auto;
}

.textarea-message.textarea-message-div {
  max-height: 70px;
}

@media only screen and (max-width: 768px) {
  .nav-admin-wrapper .nav-items.open-menu a, .nav-admin-wrapper .nav-items.open-menu summary, .nav-admin-wrapper .nav-items.open-menu button {
    height: 50px;
  }
  nav.xl-resize {
    margin-bottom: 0px !important;
  }
  .mobile-nav-container {
    padding-top: 30px;
    position: absolute;
    bottom: -70px;
    padding-top: 0;
    padding-bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .nav-admin-wrapper {
    width: 100%;
    margin-top: 0 !important;
  }
  .nav-admin-wrapper .nav-items.open-menu {
    height: calc(100vh - 300px);
    overflow-y: auto;
    display: block;
    background: transparent;
    margin-bottom: 300px !important;
  }
  main.container {
    margin-bottom: 85px;
  }
  .main-fixed {
    height: calc(100vh - 115px);
    overflow: hidden;
  }
}

@media only screen and (max-width: 576px) {
  header {
    padding-bottom: 15px !important;
  }
  #mainContent header .title-box {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 768px) {
  #mainContent {
    margin-top: 15px;
  }
  header {
    margin-bottom: 0 !important;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  #mainContent .new-ads .ads-box-wrapper::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  #mainContent .new-ads .ads-box-wrapper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  #mainContent .new-ads .ads-box-wrapper {
    height: auto;
    flex-direction: row;
    overflow-x: auto;
    overflow-y: initial;
    margin-bottom: 0;
    width: 100%;
    scroll-snap-type: x mandatory;
    scroll-snap-stop: always;
  }
  #mainContent .new-ads .rad-box {
    scroll-snap-align: start;
  }
  #mainContent .new-ads .rad-box {
    min-width: 100%;
  }
  .swipe-wrapper {
    display: flex;
  }
  #mainContent .new-ads .rad-box {
    padding: 5px 15px;
  }
  #mainContent header .btn-wrapper a {
    display: contents;
  }
  #mainContent .container-flex .btns-container button:nth-child(1) {
    margin-bottom: 15px;
  }
  #mainContent .container-flex .btns-container {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 576px) {
  #mainContent .company-s-container .stats-side h5,
  #mainContent .company-s-container .stats-side h6,
  #mainContent .company-s-container .stats-side p,
  #mainContent .cv-application .stats-side h5,
  #mainContent .cv-application .stats-side h6 {
    text-align: start;
  }
  #mainContent .new-order-1 {
    order: 0;
  }
  .swipe-wrapper.swipe-wrapper-calendar, .swipe-wrapper.swipe-wrapper-status-mob {
    display: flex;
  }
  .mobile-nav-container .btn-wrapper {
    margin-right: 0;
  }
  #mainContent .new-ads .rad-box .company-desc h3 {
    width: 100%;
    max-width: 160px;
  }
  .company-table-lg tr .company-title .name {
    width: calc(100% - 90px);
  }
  #mainContent header .btn-wrapper {
    order: 3;
  }
  .company-table-lg tr .company-title .name {
    width: calc(100% - 75px);
  }
}

@media only screen and (max-width: 460px) {
  #mainContent .new-ads {
    order: 0;
  }
  #mainContent .stats-box {
    order: 2;
  }
  #mainContent .stats-box .mobile-select {
    display: flex;
    justify-content: center;
  }

  .write-message-wrapper button.send-btn {
      width: 40px;
  }
  #mainContent .new-ads .rad-box .company-desc h3 {
    width: 100%;
    max-width: 140px;
  }

}


#mainContent .container-flex .input-wrapper.comp-desc p span {
  color: #6b6b6b !important;
  font-size: calc( 14px + (16 - 14) * ((100vw - 320px) / (1920 - 320))) !important;
  font-family: 'Rubik', sans-serif !important;
}

#mainContent .concourse-table-lg .omiljeni-oglas {
  background: transparent !important;
}


.concourse-table-lg tr .company-title.row-title .btn-circle::before {
  background: transparent;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: solid 1px #6b6b6b;
}

.concourse-table-lg tr.omiljeni-oglas .company-title.row-title button.btn-circle::before {
  height: 100% !important;
  width: 100% !important;
  border: solid 1px transparent;
}


.concourse-table-lg tr.omiljeni-oglas .company-title.row-title button.btn-circle {
  background: linear-gradient(to top, #04274f, #2897fb) !important;
  transition: 0.2s ease-in-out;
}

.uploaded .preview-video-box #editVideo, .uploaded .preview-video-box button {
  height: 35px;
  width: 35px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: linear-gradient(to top, #ee5124, #ffa837);
  color: white;
  z-index: 2;
}

.uploaded .preview-video-box #editVideo svg, .uploaded .preview-video-box button svg {
  height: 15px;
}

.about-company-more-details p {
  word-break: normal;
  white-space: nowrap;
}

.about-company-more-details input {
  margin-left: 10px;
  margin-right: 10px;
}

.uploaded .preview-logo-box {
  background: transparent;
}


#mainContent .container-flex .container-half-comp-info .input-wrapper p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

#mainContent .container-flex .current-package {
  opacity: 0;
}

@media only screen and (max-width: 768px) {
  #mainContent .container-flex .current-package {
    display: none;
  }  
}


@media only screen and (max-width: 768px) {
  .main-fixed .nav-admin-wrapper .nav-items {
    height: calc(100vh - 300px) !important;
    overflow-y: auto;
    display: block;
    background: transparent;
    margin-bottom: 300px !important;
    opacity: 1;
  }
  .nav-admin-wrapper .nav-items a, .nav-admin-wrapper .nav-items summary, .nav-admin-wrapper .nav-items button {
    height: 50px;
  }
  nav details[open] {
    padding-bottom: 10px;
  }
  nav .sublist {
    padding-top: 10px;
  }
  div[role="presentation"] {
    margin-left: 15px;
    margin-right: 15px;
  }
  nav.MuiPagination-root {
    opacity: 1;
    overflow: initial;
    height: auto;
    background: transparent;
  }
}
