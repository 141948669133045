$primary: #ffa837;
$secondary: #2897fb;

$primary_second: #ee5124;
$secondary_second: #04274f;

$primary_gradient: #ffa837, #ee5124;
$secondary_gradient: #2897fb, #04274f;

$primary_gradient_second: #ffdbad, #ffffff;

$white: #fff;

$black: #353535;

$graphite: #6b6b6b;

$success: #00c851;
$danger: #ff4444;
$warning: #ffbb33;
$info: #33b5e5;
