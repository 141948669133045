// login register form
#log-reg,
.login-register {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    height: max-content;
    position: relative;
}

.log-reg-container {
    width: 90%;
    max-width: 400px;
    height: max-content;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba($color: #000000, $alpha: 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo-box {
        width: 100%;
        max-width: 240px;
    }

    padding: 30px;

    form {
        width: calc(100% + 0px);
        height: max-content;
        display: flex;
        flex-direction: column;
        position: relative;
        padding: 30px 0px;
        margin-bottom: 30px;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        text-align: center;
        button {
            width: 100%;
            margin-bottom: 20px;
        }
    }
}

.admin-log-container {
    form {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;

    }
}

.new-pass {
    text-align: center;
    font-weight: normal;
    color: $grayl;
}

.text-btn {
    text-align: center;
    width: 100%;
    height: max-content;
    font-size: 17px;
    font-weight: 500;
    color: $grayl;
    transition: .2s ease-in-out;

    &:hover,
    &:focus {
        color: $bluel;
    }
}