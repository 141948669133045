table {
  width: 100%;
  position: relative;
}

.table-header {
  width: 100%;
  height: max-content;
  min-height: 40px;
  position: relative;
  display: flex;
  background: linear-gradient(to top, $blued, $bluel);
  margin-bottom: 10px;
  box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;
  .sub-ceil-half {
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: solid 1px rgba($color: #fff, $alpha: 0.2);
    padding: 10px;

    &:nth-last-child(1) {
      border-right: none;
    }

    .settings-toggle {
      margin-bottom: 0;
    }

    // @include mq("xl-16") {
    //   width: 50%;
    //   height: max-content;
    //   &:nth-child(1) {
    //     width: 100%;
    //     border-right: none;
    //     border-bottom: solid 1px rgba($color: #fff, $alpha: 0.2);
    //   }
    // }
  }

  .sub-ceil {
    width: 33.33%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: solid 1px rgba($color: #fff, $alpha: 0.2);
    padding: 10px;

    &:nth-last-child(1) {
      border-right: none;
    }

    .settings-toggle {
      margin-bottom: 0;
    }

    @include mq('xl-16') {
      width: 50%;
      height: max-content;

      &:nth-child(1) {
        width: 100%;
        border-right: none;
        border-bottom: solid 1px rgba($color: #fff, $alpha: 0.2);
      }
    }
  }

  .sub-ceil-quarter {
    width: 25%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-right: solid 1px rgba($color: #fff, $alpha: 0.2);
    padding: 10px 5px;

    p {
      text-align: center;
    }

    &:nth-last-child(1) {
      border-right: none;
    }

    .settings-toggle {
      margin-bottom: 0;
    }

    @include mq('xl-16') {
      width: 50%;
      height: max-content;

      &:nth-child(1),
      &:nth-child(2) {
        border-bottom: solid 1px rgba($color: #fff, $alpha: 0.2);
      }
    }
  }

  .sub-ceil-5 {
    width: 20%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-right: solid 1px rgba($color: #fff, $alpha: 0.2);
    padding: 10px;

    p {
      text-align: center;
    }

    &:nth-last-child(1) {
      border-right: none;
    }

    .settings-toggle {
      margin-bottom: 0;
    }

    @include mq('xl-16') {
      width: 33%;
      height: 50%;

      &:nth-child(1) {
        width: 50%;
        border-bottom: solid 1px rgba($color: #fff, $alpha: 0.2);
      }

      &:nth-child(2) {
        width: 50%;
        border-right: none;
        border-bottom: solid 1px rgba($color: #fff, $alpha: 0.2);
      }
    }
  }

  .sub-ceil-80 {
    width: 80%;
    height: 100%;
    position: relative;
    border-right: solid 1px rgba($color: #fff, $alpha: 0.2);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:nth-last-child(1) {
      border-right: none;
    }

    a {
      font-weight: 300;
      margin-bottom: 5px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }

      &.hvr-oragne {
        &:hover,
        &:focus {
          color: $yellowd !important;
        }
      }
    }

    @include mq('xl-14') {
      width: 100%;
      height: max-content;
      border-right: none;
      border-bottom: solid 1px rgba($color: #fff, $alpha: 0.2);

      p {
        text-align: center;
      }
    }
  }

  .sub-ceil-60 {
    width: 60%;
    height: 100%;
    position: relative;
    border-right: solid 1px rgba($color: #fff, $alpha: 0.2);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:nth-last-child(1) {
      border-right: none;
    }

    a {
      font-weight: 300;
      margin-bottom: 5px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }

      &.hvr-oragne {
        &:hover,
        &:focus {
          color: $yellowd !important;
        }
      }
    }

    @include mq('xl-14') {
      width: 100%;
      height: max-content;
      border-right: none;
      border-bottom: solid 1px rgba($color: #fff, $alpha: 0.2);

      p {
        text-align: center;
      }
    }
  }

  .sub-ceil-20 {
    width: 20%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-right: solid 1px rgba($color: #fff, $alpha: 0.2);
    padding: 10px;

    button {
      color: $grayl;
    }

    p {
      text-align: center;
    }

    &:nth-last-child(1) {
      border-right: none;
    }

    @include mq('xl-14') {
      width: 50%;
      height: 50%;

      &.width-xl14-100 {
        width: 100%;
      }
    }
  }
}

.table-row {
  width: 100%;
  height: max-content;
  min-height: 70px;
  display: flex;
  background: linear-gradient(to top, rgba(107, 107, 107, 0.1), white);
  border-radius: 10px;
  box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.2);
  margin-bottom: 10px;
  transition: 0.2s ease-in-out;

  .ceil {
    padding: 10px;
    height: 75px;
    min-height: 75px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    justify-content: center;
    border-right: solid 1px rgba($color: #000000, $alpha: 0.2);
    position: relative;

    &:nth-last-child(1) {
      border-right: none;
    }

    p {
      color: $grayl;
      font-size: 14px;
      font-weight: 300;
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .name {
      width: 100%;
    }

    button {
      height: 30px;
      width: 30px;
      color: $grayd;
      position: relative;

      svg {
        height: 25px;
        width: 25px;
        position: relative;
        transition: 0.2s ease-in-out;

        g {
          width: 100%;
          height: 100%;
          position: relative;

          rect {
            width: 100%;
            height: 100%;
          }
        }
      }

      .stats {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(to top, $yellowd, $yellowl);
        color: white;
        font-size: 13px;
        font-weight: 300;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        @include prefixer(user-select, none);
        @include prefixer(pointer-events, none);
        transition: 0.2s ease-in-out;
      }

      &.konkursi-svg {
        svg {
          width: 30px;
          height: 35px;
          opacity: 0.5;
          transition: opacity 0.2s ease-in-out;
        }
      }

      &:hover {
        .svgGradientTransition {
          opacity: 1;
        }

        &.konkursi-svg {
          svg {
            opacity: 1;
          }

          color: $bluel;
        }
      }

      &.send {
        &:hover,
        &:focus {
          color: $yellowd;
        }
      }

      &.see {
        &:hover,
        &:focus {
          svg {
            opacity: 1;
            color: $bluel;
          }
        }
      }

      &.edit {
        svg {
          height: 25px;
          width: 25px;
        }

        &:hover,
        &:focus {
          svg {
            opacity: 1;
            color: $bluel;
          }
        }
      }

      &.inactive {
        svg {
          opacity: 0.5;
        }
      }

      &.active {
        svg {
          opacity: 1;
        }

        color: $bluel;

        .stats {
          opacity: 1;
        }
      }

      // companuy btn
      &.reminder {
        height: 30px;
        width: 30px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        color: rgba($color: #000000, $alpha: 0.2);

        svg {
          height: 20px;
          transition: none;
        }

        &:hover,
        &:focus {
          color: $bluel;
        }
      }
    }

    // company
    .reminder-box {
      width: 350px;
      height: max-content;
      position: absolute;
      right: 0;
      bottom: -20px;
      padding: 10px;
      border-radius: 10px;
      background: white;
      box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
      transition: 0.2s ease-in-out;
      transform: translate(50%, 90%);
      opacity: 0;
      z-index: -1;
      display: none;
      transition: 0.2s ease-in-out;

      &::before {
        content: '';
        height: 20px;
        width: 20px;
        background: white;
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-35px);
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
      }

      p {
        text-align: start;
        margin-bottom: 10px;
      }

      .reminder-save {
        height: max-content;
        width: max-content;
        max-width: 100%;
        margin-left: auto;
        display: flex;
        align-items: center;
        color: $bluel;
        text-transform: uppercase;
        font-weight: 500;

        svg {
          margin-right: 5px;
        }
      }

      &.show {
        opacity: 1;
        z-index: 3;
        display: initial;
      }
    }

    &.padding-for-btn {
      padding-right: 40px;
    }

    .sub-ceil-half {
      width: 50%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      border-right: solid 1px rgba($color: #000000, $alpha: 0.2);
      padding: 10px;

      &:nth-last-child(1) {
        border-right: none;
      }

      .settings-toggle {
        margin-bottom: 0;
      }
    }

    .sub-ceil {
      width: 33.33%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      border-right: solid 1px rgba($color: #000000, $alpha: 0.2);
      padding: 10px;

      &:nth-last-child(1) {
        border-right: none;
      }

      .settings-toggle {
        margin-bottom: 0;
      }
    }

    .sub-ceil-quarter {
      width: 25%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      border-right: solid 1px rgba($color: #000000, $alpha: 0.2);
      padding: 10px;

      p {
        text-align: center;
      }

      &:nth-last-child(1) {
        border-right: none;
      }

      .settings-toggle {
        margin-bottom: 0;
      }
    }

    .sub-ceil-5 {
      width: 20%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      border-right: solid 1px rgba($color: #000000, $alpha: 0.2);
      padding: 10px;

      p {
        text-align: center;
      }

      &:nth-last-child(1) {
        border-right: none;
      }

      .settings-toggle {
        margin-bottom: 0;
      }

      @include mq('xl-16') {
        width: 33%;
        height: 50%;

        &:nth-child(1) {
          width: 50%;
          border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        }

        &:nth-child(2) {
          width: 50%;
          border-right: none;
          border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        }
      }
    }

    // company table
    .sub-ceil-80 {
      width: 80%;
      height: 100%;
      position: relative;
      border-right: solid 1px rgba($color: #000000, $alpha: 0.2);
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:nth-last-child(1) {
        border-right: none;
      }

      a {
        font-weight: 300;
        margin-bottom: 5px;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        &.hvr-oragne {
          &:hover,
          &:focus {
            color: $yellowd !important;
          }
        }
      }

      @include mq('xl-14') {
        width: 100%;
        height: max-content;
        border-right: none;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
      }
    }

    .sub-ceil-60 {
      width: 60%;
      height: 100%;
      position: relative;
      border-right: solid 1px rgba($color: #000000, $alpha: 0.2);
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &:nth-last-child(1) {
        border-right: none;
      }

      a {
        font-weight: 300;
        margin-bottom: 5px;

        &:nth-last-child(1) {
          margin-bottom: 0;
        }

        &.hvr-oragne {
          &:hover,
          &:focus {
            color: $yellowd !important;
          }
        }
      }

      @include mq('xl-14') {
        width: 100%;
        height: max-content;
        border-right: none;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
      }
    }

    .sub-ceil-20 {
      width: 20%;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      border-right: solid 1px rgba($color: #000000, $alpha: 0.2);
      padding: 10px;

      button {
        color: $grayd;
      }

      p {
        text-align: center;
      }

      &:nth-last-child(1) {
        border-right: none;
      }

      @include mq('xl-14') {
        width: 50%;
        height: 50%;
      }
    }
  }

  .ceil-with-logo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    .logo-holder {
      width: 55px;
      height: 55px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      background-color: white;
      img {
        // width: 100%;
        // height: 100%;
        width: 80%;
        height: 80%;
        object-fit: contain;
        object-position: center;
      }
    }
    .ceil-text-wrap {
      width: calc(100% - 65px);
      height: max-content;
      display: flex;
      flex-direction: column;
      position: relative;
    }
  }

  .last-ceil {
    padding: 0;
  }

  &.favourite {
    background: linear-gradient(to top, rgba(182, 48, 15, 0.3), white);

    &:hover {
      background: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.3),
        rgb(0, 0, 0)
      ) !important;
    }

    .favourite-btn {
      .svgGradientTransition {
        opacity: 1;
      }
    }
  }

  &:hover,
  &:focus {
    &.favourite {
      background: linear-gradient(
        to top,
        rgba(40, 151, 251, 0.2),
        white
      ) !important;

      &:hover {
        background: linear-gradient(
          to top,
          rgba(231, 59, 16, 0.486),
          rgba(255, 199, 199, 0.966)
        ) !important;
      }
    }

    background: linear-gradient(to top, rgba(40, 151, 251, 0.2), white);
  }
}

.column {
  height: auto;
  border-right: solid 1px rgba($color: #fff, $alpha: 0.2);
  color: white;
  padding: 10px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  p {
    font-size: 12px !important;

    font-weight: 300;
    color: inherit;
    text-transform: uppercase;
    word-break: break-all;
  }

  &:nth-last-child(1) {
    border-right: none;
  }
}

.text-wrap {
  .ceil {
    height: auto;
  }

  p {
    text-overflow: initial !important;
    overflow: initial !important;
    white-space: initial !important;
    word-break: break-all !important;
  }
}

.xs-column {
  width: 5%;
}

.s-column {
  width: 7.5%;
}

.m-column {
  width: 10%;
}

.m-column-12 {
  width: 12.5%;
}

.l-column {
  width: 15%;
}

.l-column-17 {
  width: 17.5%;
}

.l-column-20 {
  width: 20%;
}

.l-column-22 {
  width: 22.5%;
}

.l-column-25 {
  width: 25%;
}

.xl-column {
  width: 27.5%;
}

.xl-column-30 {
  width: 30%;
}

.xxl-column {
  width: 35%;
}

// home page table
.column-5 {
  width: 5%;
  padding-right: 10px;
}

.column-10 {
  width: 10%;
  padding-right: 10px !important;
}

.column-12 {
  width: 12.5%;
  padding-right: 10px !important;
}

.column-15 {
  width: 15%;
  // padding-right: 10px !important;
}

.column-17 {
  width: 17.5%;
  padding-right: 10px !important;
}

.column-20 {
  width: 20%;
  padding-right: 10px !important;
}

.column-22 {
  width: 22.5%;
  padding-right: 10px !important;
}

.column-25 {
  width: 25%;
  padding-right: 10px !important;
}

// big colums
.column-40 {
  width: 40%;
}

.column-42 {
  width: 42.5%;
}

.column-45 {
  width: 45%;
}

.column-47 {
  width: 47.5%;
}

// table lg
.concourse-table-lg {
  display: none;

  tbody {
    display: block;
    width: 100%;
    position: relative;
  }

  tr {
    width: 100%;
    height: max-content;
    background: linear-gradient(to top, rgba(107, 107, 107, 0.1), white);
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.2);
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    position: relative;

    p {
      width: calc(100% - 40px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: $grayl;
    }

    .link {
      width: calc(100% - 40px);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: $grayl;
    }

    .row-title {
      width: 100%;
      height: max-content;
      padding: 10px;
      display: flex;
      align-items: center;
      padding: 15px 10px;
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);

      button {
        height: 21px;
        width: 23px;
        color: $grayd;
        margin-right: 17px;

        svg {
          height: 21px;
          width: 23px;
          position: relative;

          g {
            width: 100%;
            height: 100%;
            position: relative;

            rect {
              width: 100%;
              height: 100%;
            }
          }
        }

        &.konkursi-svg {
          svg {
            opacity: 0.5;
            transition: opacity 0.2s ease-in-out;
          }
        }

        &:hover,
        &.active {
          .svgGradientTransition {
            opacity: 1;
          }

          &.konkursi-svg {
            color: $bluel;

            svg {
              opacity: 1;
            }
          }
        }
      }
    }

    .row-body {
      padding: 15px 50px 15px 10px;
      width: 100%;
      height: max-content;
      position: relative;
      display: flex;
      flex-direction: column;

      p {
        width: 100%;
        margin-bottom: 5px;

        &.w-box {
          width: calc(100% - 100px);
        }
      }

      button {
        height: 40px;
        width: 40px;
        color: $grayl;
        position: absolute;
        right: 10px;
        bottom: 10px;

        svg {
          height: 21px;
          width: 23px;
          position: relative;

          g {
            width: 100%;
            height: 100%;
            position: relative;

            rect {
              width: 100%;
              height: 100%;
            }
          }
        }

        &:hover,
        &:focus {
          color: $yellowd;
        }
      }

      // konkursi button position
      .buttons-box {
        width: max-content;
        height: max-content;
        display: flex;
        align-items: center;
        position: absolute;
        right: 10px;
        bottom: 10px;

        button {
          height: 40px;
          width: 40px;
          position: relative;
          color: $grayl;
          bottom: initial;
          right: initial;

          svg {
            width: 30px;
            height: 35px;
            opacity: 0.5;
            transition: opacity 0.2s ease-in-out;
          }

          .stats {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            position: absolute;
            top: 0;
            right: 0;
            background: linear-gradient(to top, $yellowd, $yellowl);
            color: white;
            font-size: 13px;
            font-weight: 300;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            @include prefixer(user-select, none);
            @include prefixer(pointer-events, none);
            transition: 0.2s ease-in-out;
          }

          &.edit {
            svg {
              height: 25px;
            }

            margin-left: 20px;
          }

          &:nth-child(1) {
            margin-right: 20px;
          }

          &.active {
            color: $bluel;

            svg {
              opacity: 1;
            }

            .stats {
              opacity: 1;
            }
          }

          &:hover,
          &:focus {
            color: $bluel;

            svg {
              opacity: 1;
            }
          }
        }

        .settings-toggle {
          margin-bottom: 0;
        }
      }

      .two-btn-box {
        button {
          margin: 0 !important;

          &:nth-last-child(1) {
            margin-left: 20px !important;
          }
        }
      }
    }

    &:hover,
    &:focus {
      .link {
        color: $bluel;
      }
    }

    &.favourite {
      background: linear-gradient(to top, rgba(182, 48, 15, 0.2), white);

      .row-title {
        border-color: rgba(182, 48, 15, 0.4);
      }

      .favourite-btn {
        .svgGradientTransition {
          opacity: 1;
        }
      }

      &:hover,
      &:focus {
        .link {
          color: $yellowd;
        }
      }

      .btn-circle {
        border-color: transparent !important;

        &::before {
          opacity: 1;
        }
      }

      // .name{
      //     color: $bluel;
      // }
    }
  }
}

.company-table-lg {
  tr {
    .company-title {
      p {
        width: calc(100% - 108px);
        margin-right: 10px;
        white-space: pre-wrap;
      }

      .name {
        width: calc(100% - 108px);
        margin-right: 10px;
      }

      .comapny-title-btns-wrapper {
        display: flex;
        align-items: center;

        .btn-candidate {
          color: $grayl;

          &:hover,
          &:focus {
            color: $bluel;
          }
        }

        .btn-mail {
          margin-right: 0;

          &:hover,
          &:focus,
          &.active {
            color: $bluel;
          }
        }
      }
    }

    .com-title {
      .name {
        width: calc(100% - 35px);
        margin-right: 0;
      }
    }

    .row-body {
      p {
        white-space: pre-wrap;
      }

      .play-btn {
        color: $bluel;
        right: 0;

        svg {
          height: 40px;
        }

        &:hover,
        &:focus {
          color: $yellowd;
        }
      }
    }

    &.favourite {
      background: linear-gradient(to top, rgba(107, 107, 107, 0.1), white);

      .row-title {
        border-color: rgba($color: #000000, $alpha: 0.2);
      }

      .favourite-btn {
        .svgGradientTransition {
          opacity: 1;
        }
      }

      &:hover,
      &:focus {
        .link {
          color: $yellowd;
        }
      }

      .btn-circle {
        border-color: transparent !important;

        &::before {
          opacity: 1;
        }
      }

      // .name{
      //     color: $bluel;
      // }
    }
  }
}

// oglasi kompanija
.favourite-company {
  &.favourite-2 {
    background: linear-gradient(
      to top,
      rgba(53, 53, 53, 0.4),
      white
    ) !important;

    .ceil {
      border-color: rgba($color: #000000, $alpha: 0.2) !important;

      .sub-ceil {
        border-color: rgba($color: #000000, $alpha: 0.2) !important;
      }
    }

    .btn-circle {
      border-color: transparent;

      &::before {
        opacity: 1;
      }
    }
  }
}

.omiljeni-oglas {
  background: linear-gradient(to top, rgba(53, 53, 53, 0.2), white) !important;

  .ceil {
    border-color: rgba($color: #000000, $alpha: 0.2) !important;

    .sub-ceil {
      border-color: rgba($color: #000000, $alpha: 0.2) !important;
    }
  }

  .btn-circle {
    border-color: transparent;

    &::before {
      opacity: 1;
    }
  }
}
