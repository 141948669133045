.loader-wrapper {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
}
