.border-b{
    border-bottom: 1px solid rgba($color: #000000, $alpha: 0.2);
}

.border-t{
    border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
}

.border-l{
    border-left: 1px solid rgba($color: #000000, $alpha: 0.2);
}

.border-r{
    border-right: 1px solid rgba($color: #000000, $alpha: 0.2);
}