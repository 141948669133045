.password-actions {
  align-self: flex-end;
  margin-top: 50px;
  span {
    color: #2897fb;
    &:hover {
      cursor: pointer;
      color: #125495;
    }
  }
}

.settings-main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.settings-half {
  max-width: 500px;
}
