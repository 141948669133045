$wb-gradient-new: linear-gradient(#ffffff, #eff3f6);
$wb-gradient-reverse: linear-gradient(#eff3f6, #ffffff);
$color-muted-new: #6b6b6b;
$primary-color-new: #2999ff;

.bg-gradient-wb {
  background: $wb-gradient-new;
}

.bg-gradient-reverse {
  background: $wb-gradient-reverse;
}

.text-muted {
  color: $color-muted-new;
  font-weight: 100;
}

.prim-color {
  color: $primary-color-new;
}

.bold {
  font-weight: bold;
}
.bb {
  border: 10px solid rgb(255, 255, 255);
}

.spinner-container {
  height: 100%;
}

.gray-heart {
  transition: 0.5s ease-in-out;

  path {
    fill: rgba(206, 206, 206, 0.678) !important;
  }
}

.gray-heart:hover {
  transition: 0.5s ease-in-out;
  path {
    fill: #db6454 !important;
  }
}

.filled-heart {
  transition: 0.5s ease-in-out;
  path {
    fill: #db6454 !important;
  }
}

.filled-heart:hover {
  transition: 0.5s ease-in-out;

  path {
    fill: rgba(206, 206, 206, 0.678) !important;
  }
}

.blue-bar {
  background-color: $primary-color-new;
  width: 15px !important;
}

.mui-menu-item {
  background-color: #fff;
}

.month-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  width: 100%;

  p {
    font-size: 12px;
    @media only screen and (max-width: 600px) {
      & {
        font-size: 10px;
      }
    }
  }

  .data-fill-month {
    width: 100%;
    height: 20px;
    min-height: 10px;
    background: linear-gradient(to top, $blued, $bluel);
    border-radius: 7px;
    max-width: 40px;
  }

  .data-fill-month-yl {
    background: linear-gradient(to top, $yellowd, $yellowl);
    height: 10px;
    border-radius: 7px;
    width: 100%;
    max-width: 40px;
  }
}

.not-found-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.not-found-container {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  .not-found-title {
    font-size: 6em;
  }

  .not-found-btn {
    background: linear-gradient(#2999ff, #03254b);
    color: white;
    text-transform: uppercase;
    padding: 15px 50px;
    border-radius: 10px;
    font-weight: 500;
  }
}

.video-placeholder {
  display: block;
  //background: url("/images/mockup/video-placeholder.png") no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.messag-all-btn {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
  a {
    background: linear-gradient(#2999ff, #03254b);
    color: white;
    padding: 10px 40px;
    border-radius: 10px;
    width: 100%;
  }
}

.link-edit {
  color: white;
  font-weight: 500;
  width: 100%;
}

.link-edit:hover {
  color: white;
}

.link-hover:hover {
  color: white;
}

.warning-note {
  color: #d54343;
  padding-bottom: 20px;
}

.column-header-content {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .icons-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: start;
    align-items: center;
    div {
      padding: 0;
      &:first-child {
        -ms-transform: rotate(180deg);
        transform: rotate(180deg);
        margin: -10px;
      }

      &:last-child {
      }
      svg {
        padding: 0;
        margin: 0;
        cursor: pointer;
      }

      svg:hover {
        fill: white;
        transition: 0.2s ease-in-out;
      }
    }
  }
}

.empty-table {
  margin: auto;
  display: flex;
  flex-direction: column;
  margin-top: 7%;

  p {
    font-weight: 500;
    color: gray;
    margin-top: 20px;
  }
}

.btns-video-box {
  display: flex;
  border: 1px solid black;
}

.btn-filled {
  background: linear-gradient(to top, $blued, $bluel);
}

.my-btn {
  border-color: white;
}

.white-circle {
  background: white;
}

.upload-new-video {
  position: absolute;
  left: 10px;
}

.gray-heart:active {
  animation: shake 0.5s ease-in;
  animation-iteration-count: inherit;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-2px, -3px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(4px, 3px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-2px, 3px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(4px, 2px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 3px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.warning-modal {
  background: linear-gradient(to top, #ffc8b8, #ffffff) !important;
}
.text-center {
  text-align: center !important;
}

.eye-icon-div {
  position: relative;

  .eye-icon {
    position: absolute;
    top: 50%;
    right: 3%;
    cursor: pointer;
  }
}

.jodit-editor-cv {
  border: 1px solid rgb(187, 187, 187);
  border-radius: 15px;
  text-align: left;

  & > * {
    border-radius: 15px !important;
    border: 1px solid rgb(187, 187, 187);
  }
}

.div-new-company {
  text-align: justify;
  word-break: break-word; /* optionally */
  height: 200px !important;
  overflow-x: hidden;
  overflow-y: auto;
}

.employee-numbers {
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;
  align-items: center;
}

.normal-text {
  font-weight: normal;
}

.w-select-10 {
  width: 120px !important;
}

select {
  text-align: left !important;

  option {
    font-family: Arial, Helvetica, sans-serif !important;
  }
}

.max-width-search-input {
  width: 520px;
  .search-wrapper {
    width: 90% !important;

    input {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 1600px) and (min-width: 1201px) {
  .column p {
    font-size: 11px !important;
  }

  .table-header .sub-ceil:nth-child(1),
  .table-header .sub-ceil,
  #mainContent .last-ceil .sub-ceil:nth-child(1),
  #mainContent .last-ceil .sub-ceil:nth-child(2),
  #mainContent .last-ceil .sub-ceil:nth-child(3) {
    width: 33.33%;
    height: 100%;
  }
  nav {
    width: 175px;
  }
  nav details summary {
    height: 50px;
  }
  nav .sublist {
    padding-left: 40px;
  }
  nav a {
    height: 50px;
  }
  .admin-logo-box img {
    object-fit: contain;
    object-position: left;
    width: 180px;
    height: 60px;
  }
  .ico-box {
    width: 40px;
    height: 40px;
    margin-top: 10px;
  }
  .table-row,
  .table-row .ceil {
    min-height: 60px;
  }
  #mainContent {
    width: calc(100% - 205px);
    margin-left: 30px;
  }
  nav details summary span,
  nav a p {
    font-size: 15px;
  }
  nav details summary svg {
    width: 15px;
    margin-right: 10px;
  }
  a.submenu {
    font-size: 14px;
  }
  nav a svg {
    width: 15px;
    margin-right: 10px;
  }
  header {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }
  #mainContent .last-ceil .sub-ceil:nth-child(1) {
    border-right: solid 1px rgba(0, 0, 0, 0.2);
    border-bottom: none;
  }
  .container.main-js {
    width: calc(340px + (1800 - 300) * ((100vw - 320px) / (1920 - 320)));
    padding: 20px;
    margin: 20px auto;
  }
  .table-header .sub-ceil:nth-child(1) {
    border-right: solid 1px rgba(255, 255, 255, 0.2);
    border-bottom: none;
  }
  .settings-toggle {
    height: 20px;
    width: 35px;
  }
  .settings-toggle::before {
    height: 10px;
    width: 10px;
  }
  .table-row .ceil button.edit svg,
  .table-row .ceil button svg {
    height: 18px;
    width: 18px;
  }
  #mainContent header .btn-wrapper button {
    min-height: 40px;
    padding: 12px 10px;
  }
  #mainContent .search-box-wrapper .input-class,
  #mainContent .search-box-wrapper select {
    height: 40px;
  }
  #mainContent .search-box-wrapper .settings-btn {
    height: 40px;
    width: 40px;
  }
  #mainContent .search-box-wrapper .search-wrapper .search {
    height: 40px;
    width: 40px;
  }
  #mainContent .all-btns {
    min-height: 40px;
    padding: 12px 10px;
  }
  .container.main-js .all-btns {
    min-height: 40px;
    padding: 12px 10px;
  }
  .month-cont .data-fill-month-yl,
  .month-cont .data-fill-month {
    width: 30px !important;
  }
  .container.main-js .btn-stats {
    transform: translate(10%, 10%);
  }
  #mainContent {
    .published-main-packages-content-desktop .benefits-side p {
      font-size: 14px;
    }
    .published-main-packages-container
      .published-main-packages-header
      .toggle-btn-wrapper
      .price-toggle-btn {
      min-height: 40px;
      padding: 12px 10px;
    }
    .published-main-packages-content-desktop .carousel-side {
      width: 70%;
    }
    .published-main-packages-content-desktop .benefits-side {
      width: 28%;
    }
    .published-main-packages-content-desktop .carousel-side p.last,
    .packages-content-desktop-wrapper
      .toggle-package-content-box
      .content-side
      .price-wrapper
      .price {
      font-size: 28px;
    }
    .packages-content-desktop-wrapper
      .toggle-package-content-box
      .list-side
      li {
      font-size: 14px;
    }
    .packages-content-desktop-wrapper
      .toggle-package-content-box
      .content-side {
      width: 59%;
    }
    .packages-content-desktop-wrapper .toggle-package-content-box .list-side {
      width: 36%;
      margin-right: 5%;
    }
    .packages-content-desktop-wrapper
      .packages-content-toggle-btn-wrapper
      .toggle-btn-wrapper
      .select-label-wrapper {
      padding: 12px;
    }
  }

  #mainContent .new-ads .title-box {
    margin-bottom: 20px;
  }
  #mainContent .container-flex .current-package .text-wrapper {
    width: max-content;
    margin-bottom: 0;
  }
  #mainContent .container-flex .current-package button {
    width: initial;
  }
  #mainContent .container-flex .btns-container button {
    width: 48%;
  }

  #mainContent {
    .table-row .ceil .sub-ceil-60 {
      width: 60%;
      border-right: solid 1px rgba(0, 0, 0, 0.2);
      border-bottom: none;
      height: 100%;
    }
    .table-row .ceil .sub-ceil-20 {
      width: 20%;
      height: 100%;
    }
    .table-row .ceil .sub-ceil-quarter {
      padding: 5px;
    }

    .table-row .ceil button {
      height: 30px;
      width: 30px;
    }

    .table-row .ceil button.konkursi-svg svg {
      height: 18px;
      width: 18px;
    }
  }

  #mainContent .new-ads .title-box {
    padding: 15px 20px;
  }

  #mainContent .stats-box .title-box {
    padding: 11px 20px;
  }

  #mainContent .cv-application .ico-side {
    padding: 20px;
  }

  // #mainContent .new-ads.new-order-1 .ads-box-wrapper {
  //   height: 180px;
  // }

  .company-s-container .ico-side svg {
    height: 25px;
  }

  .company-s-container .ico-side {
    padding: 12px 15px;
  }

  #mainContent .stats-box .select-wrapper #year {
    padding: 5px 15px 5px 0;
  }

  #mainContent .cv-application .ico-side {
    padding: 12px 20px;
  }

  #mainContent {
    .table-header .sub-ceil-quarter,
    .last-ceil .sub-ceil-quarter {
      width: 25% !important;
    }
    .last-ceil .sub-ceil-quarter {
      height: 100% !important;
      border-bottom: none;
    }
    .last-ceil .sub-ceil-quarter:nth-child(2n) {
      height: 100% !important;
      border-right: solid 1px rgba(0, 0, 0, 0.2);
    }
    .table-row .ceil .sub-ceil-quarter:nth-last-child(1) {
      border-right: none;
    }

    .table-row .ceil p {
      font-size: 13px;
    }

    a {
      font-size: 13px;
    }

    .table-row .ceil .sub-ceil-5 {
      padding: 5px;
    }

    .container-flex .btns-container button:nth-child(1) {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: 1300px) and (min-width: 1201px) {
  #mainContent {
    .published-main-packages-container .published-main-packages-header,
    .published-main-packages-container .published-main-packages-content {
      width: calc(100% - 115px);
    }
  }
}

.btn-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
  filter: grayscale(1);
}

.btn-circle-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
  filter: grayscale(1);
}

.btn-circle.btn-circle-disabled::before {
  background: transparent !important;
  border: solid 1px #6b6b6b;
}

.spinner {
  margin: 100px auto;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  // background-color: #333;
  background: linear-gradient(to top, #04274f, #2897fb);
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
