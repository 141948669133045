@import "../../../scss/colors";

.cv-uploadimage-main {
  width: 180px;
  height: 180px;
  position: relative;
  .cv-uploadimage-image-holder {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 50%;
    border: 1px solid rgba($black, 0.1);
    background-color: $white;
    overflow: hidden;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
    div {
      color: #fff;
      font-weight: 500;
      font-size: 5rem;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
    .cv-uploadimage-image-uploading {
      position: absolute;
      left: 0px;
      top: 0px;
      background-color: rgba($white, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
  .cv-uploadimage-image-button {
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    min-width: unset;
    border-radius: 50%;
  }
  .cv-uploadimage-image-button-holder {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
}
