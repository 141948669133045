
.ad-container{
    display: flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    .logo-box2{
        width: 60px;
        img{
            border-radius: 50%;
            border: 1px solid rgb(138, 138, 138);

        }
    }

    .title-text-wrap{
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis; 
        max-width: 300px;
    }
}

.ad-container:hover{
    .logo-box2 {
        img{
            border: 1px solid #2897FB;
        }
    }

    h3{
        color: #2897FB;
    }
    
}