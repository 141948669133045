@import "../../../scss/colors";
@import "../../../scss/breakpoints";

.cv-dashboard-profile-driving-licence {
  width: 100%;
}
.cv-dashboard-profile-col-triple {
  grid-column-start: 1;
  grid-column-end: 4;
}

.cv-dashboard-profile-edit-modal-main {
  width: 100%;
  display: flex;
  @include sm {
    flex-wrap: wrap;
  }
  .cv-dashboard-profile-edit-modal-side {
    width: 100%;
    &:not(:last-child) {
      margin-right: 20px;
    }
    @include sm {
      &:not(:last-child) {
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }
  }
}

.cv-dashboard-profile-edit-modal-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  @include sm {
    display: block;
    & > * {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
    }
  }
}

.cv-dashboard-profile-edit-modal-grid-lang {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}

.cv-dashboard-profile-end-date-in-process {
  display: flex;
  justify-content: flex-end;
  label {
    cursor: pointer;
  }
}

.cv-dashboard-profile-edit-single {
  cursor: pointer;
  padding: 10px;
  &:hover {
    background-color: rgba($black, 0.1);
  }
}

.cv-dashboard-profile-edit-active {
  background-color: rgba($secondary, 0.1) !important;
  .cv-data-display-label {
    color: $secondary;
  }
  .cv-data-display-value {
    color: $secondary;
  }
}

.cv-dashboard-page-scrollable {
  width: calc(100% + 40px);
  // height: calc(100% + 40px);
  // overflow-y: auto;
  margin: -20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: flex-start;
  &::-webkit-scrollbar {
    width: 0px;
    opacity: 0;
  }
  @include xl {
    padding: 15px;
  }
}

.cv-dashboard-order-content {
  order: 2;
  @include md {
    order: 1;
  }
}

.cv-dashboard-profile-card {
  min-height: 200px;
}

.cv-dashboard-profile-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  @include md {
    grid-template-columns: 1fr;
  }
}

.cv-dashboard-profile-col-double {
  grid-column-start: 1;
  grid-column-end: 3;
  @include md {
    grid-column-end: 2;
  }
}

.cv-dashboard-dashboard-progress {
  width: 140px;
  height: 140px;
  margin: 0px auto;
  .cv-dashboard-dashboard-progress-circle {
    width: 100% !important;
    height: 100% !important;
  }
  @include xl {
    width: 120px;
    height: 120px;
  }
  @include sm {
    width: 80px;
    height: 80px;
    margin: 0px;
    div {
      font-size: 16px;
    }
  }
}

.cv-dashboard-profile-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  @include md {
    grid-template-columns: 1fr;
  }
}

.cv-dashboard-profile-upload {
  margin-bottom: 20px;
}

.cv-dashboard-bottom-actions {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  order: 3;
  & > * {
    min-width: 300px !important;
    @include md {
      width: 100%;
    }
    &:not(:last-child) {
      margin-right: 20px;
      @include md {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }
  }
  @include md {
    flex-wrap: wrap;
    order: 2;
  }
}

.cv-dashboard-button-container {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  order: 3;
  & > * {
    min-width: 300px !important;
    @include md {
      width: 100%;
    }
    &:not(:last-child) {
      margin-right: 20px;
      @include md {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }
  }
  @include md {
    flex-wrap: wrap;
    order: 2;
  }
}

.cv-dashboard-cvvideo-modal-content {
  text-align: center;
  padding: 20px 0px;
  color: rgba($black, 0.8);
}
