@import "../../../scss/transitions";

.cv-image-load {
  opacity: 0;
  transition: $classic opacity, 500ms ease-in-out filter;
  filter: blur(10px);
  &.cv-image-load-success {
    opacity: 1;
    filter: blur(0px);
  }
}
