.mt-1 {
  margin-top: 10px !important;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px;
}
.mt-5 {
  margin-top: 50px !important;
}

.mt-n1 {
  margin-top: -10px !important;
}
.mt-n15 {
  margin-top: -5px !important;
}
.mt-n2 {
  margin-top: -20px !important;
}
.mt-n3 {
  margin-top: -30px !important;
}

/*Left margins*/
.ml-1 {
  margin-left: 10px !important;
}
.ml-2 {
  margin-left: 20px;
}
.ml-3 {
  margin-left: 30px;
}
.ml-4 {
  margin-left: 40px;
}
.ml-5 {
  margin-left: 50px;
}

/* Bottom margins **/
.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mb-4 {
  margin-bottom: 40px;
}
.mb-5 {
  margin-bottom: 50px;
}

/* Right margins **/
.mr-1 {
  margin-right: 10px;
}
.mr-2 {
  margin-right: 20px;
}
.mr-3 {
  margin-right: 30px;
}
.mr-4 {
  margin-right: 40px;
}
.mr-5 {
  margin-right: 50px;
}
.m-auto {
  margin: auto;
}

@media screen and (min-width: 400px) {
  .w-320px {
    width: 100%;
  }
}

.p-0 {
  padding: 0;
}

.p-1131 {
  padding: 10px 10px 30px 10px;
}

/** Width */
.w-200px {
  width: 200px;
}

.w-320px {
  width: 320px;
}
