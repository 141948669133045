// FONTS
/* rubik-300 - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: local(''),
    url('./fonts/rubik-v12-latin-ext_latin-300.woff2') format('woff2'),
    url('./fonts/rubik-v12-latin-ext_latin-300.woff') format('woff');
}

/* rubik-regular - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('./fonts/rubik-v12-latin-ext_latin-regular.woff2') format('woff2'),
    url('./fonts/rubik-v12-latin-ext_latin-regular.woff') format('woff');
}

/* rubik-500 - latin-ext_latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('./fonts/rubik-v12-latin-ext_latin-500.woff2') format('woff2'),
    url('./fonts/rubik-v12-latin-ext_latin-500.woff') format('woff');
}

// COLORS
$bluel: #2897fb;
$blued: #04274f;
$redl: #fd9292;
$redd: #c52424;
$yellowl: #ffa837;
$yellowd: #ee5124;
$grayl: #6b6b6b;
$grayd: #353535;
$bg: #f1f4f7;

.color-danger {
  color: $yellowd !important;
}
// SCREEN
$minw: 320;
$maxw: 1920;

* {
  box-sizing: border-box;
  font-family: 'Rubik';
  font-weight: normal;
  outline-color: $bluel;
  margin: 0;
}

*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Rubik';
  font-weight: normal;
  outline-color: $bluel;
}

::selection {
  color: white;
  background-color: $bluel;
}

html {
  // font-size: 62.5%;
}

body {
  background: linear-gradient(to top, $bg, white);
  background-repeat: no-repeat;
}

main,
footer {
  z-index: 3;
}

.no-scroll {
  height: 100%;
  overflow: hidden;
}

.light {
  font-weight: 300 !important;
}

.normal {
  font-weight: normal !important;
}

strong {
  font-weight: 500;
}

// screen
@function calculate($max, $min) {
  @return calc(
    #{$min}px + (#{$max} - #{$min}) *
      ((100vw - #{$minw}px) / (#{$maxw} - #{$minw}))
  );
}

@function calculateLimit($minmax) {
  @return #{$minmax}px;
}

@mixin func($propery, $max, $min) {
  #{$propery}: calculate($max, $min);

  @media only screen and (min-width: #{$maxw}px) {
    #{$propery}: calculateLimit($max);
  }

  @media only screen and (max-width: #{$minw}px) {
    #{$propery}: calculateLimit($min);
  }
}

// media queries
$breakpoints: (
  'sm-3': 320px,
  'sm-4': 460px,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xl-14': 1400px,
  'xl-16': 1600px,
  'xl-18': 1800px,
  'xl-19': 1920px
);

@mixin mq($width, $type: max) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }

  @media print {
    @content;
  }
}

// prefixer
$prefixes: ('-webkit-', '-moz-', '-ms-', '-o-');

@mixin prefixer($property, $value) {
  #{$property}: $value;

  @each $prefix in $prefixes {
    #{$prefix + $property}: $value;
  }
}

// TEXT
p {
  @include func(font-size, 16, 14);
  font-weight: normal;
  color: $grayl;
}

a {
  @include func(font-size, 15, 14);
  color: $grayl;
  font-weight: 900;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  outline: none;
  position: relative;
  cursor: pointer;

  &:hover,
  &:focus,
  &.active {
    color: $bluel;
  }

  svg {
    height: 14px;
    margin-right: 5px;
  }
}

h1 {
  @include func(font-size, 36, 24);
  line-height: 1.1em;
  color: $bluel;
  font-weight: 500;
  margin-bottom: 10px;
}

h2 {
  @include func(font-size, 22, 16);
  line-height: 1.1em;
  color: $bluel;
  font-weight: 500;
  text-transform: uppercase;
}

h3 {
  @include func(font-size, 22, 16);
  line-height: 1.1em;
  color: $grayl;
  font-weight: 500;
  margin-bottom: 5px;
}

h5 {
  color: $grayd;
  @include func(font-size, 18, 14);
  font-weight: 500;
}

.one-line-text {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

ul,
ol {
  list-style-position: inside;
}

// OTHER
.container-fluid {
  height: max-content;
  width: 100%;
  position: relative;
}

.container {
  height: max-content;
  @include func(width, 1800, 300);
  position: relative;
  background: linear-gradient(to top, white, $bg);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
  padding: 30px;
  margin: 30px auto;

  @include mq('sm-4') {
    padding: 15px;
  }
}

// flex
.df-cc {
  display: flex;
  align-content: center;
  justify-content: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.justify-between {
  justify-content: space-between;
}

.justify-end {
  justify-content: flex-end;
}

.align-end {
  align-items: flex-end;
}

// other
.block-line {
  display: block;
  width: 100%;
}

.selected-candidates {
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 10px;

  p {
    margin-right: 0 !important;
  }
}

.hmc {
  height: max-content;
}

.bs {
  box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.2);
}

.wrapper {
  height: max-content;
  width: max-content;
  position: relative;
}

.d-none {
  display: none !important;
}

.no-tr {
  transition: none !important;
}

.no-border {
  border: none !important;
}

.add-border-bottom {
  border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
}

.word-break {
  word-break: break-all !important;
}

.break-all {
  word-break: break-all !important;
}

.break-normal {
  word-break: normal !important;
}

.position-br {
  align-self: flex-end;
  margin-left: auto;
}

// show hide
.hide-on-mobile-sm {
  @include mq('sm') {
    display: none !important;
  }
}

.hide-on-mobile {
  @include mq('sm-4') {
    display: none !important;
  }
}

.show-xl {
  display: none !important;

  @include mq('xl') {
    display: flex !important;
  }
}

.hide-xl {
  @include mq('xl') {
    display: none !important;
  }
}

.hide-md {
  @include mq('md') {
    display: none !important;
  }
}

.show-md {
  display: none !important;

  @include mq('md') {
    display: flex !important;
  }
}

.show-md-inline-block {
  display: none;

  @include mq('md') {
    display: inline-block;
  }
}

// for modals
.box {
  height: max-content;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  p {
    font-weight: 500;
    margin-bottom: 10px;

    span {
      color: $yellowd;
    }
  }

  /*input {
    margin-bottom: 0;
  }*/
}

.box-100 {
  width: 100%;
}

.box-50 {
  width: 48%;
}
// for ads
.box-70 {
  width: 66% !important;
  @include mq('xl-14') {
    width: 58% !important;
  }
  @include mq('lg') {
    width: 100% !important;
  }
}
.calendar-box-30 {
  width: 31%;
  @include mq('xl-14') {
    width: 38%;
  }
  @include mq('lg') {
    width: 100%;
    max-width: 400px;
    margin-bottom: 30px;
  }
}

.box-divider {
  width: 100%;
  height: 1px;
  background: rgba($color: #000000, $alpha: 0.2);
  margin-bottom: 20px;
}

.box-wrap {
  width: 100%;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

.w-full {
  width: 100%;
}

.w-full-btn {
  width: calc(100% - 60px);
}
.w-full-btn-2 {
  width: calc(100% - 120px);
}

.w-half {
  width: 48%;
}

.w-third {
  width: 31%;
}

.w-quater {
  width: 22%;
}

// margins
.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-auto {
  margin-top: 30px;
  margin-left: auto;
}
.ml-20 {
  margin-left: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.no-btn-margin {
  button {
    margin: 0 !important;
  }
}

.p-0 {
  padding: 0 !important;
}

.force-space-b {
  margin-bottom: 20px !important;
}

.force-space-l {
  margin-left: 20px !important;
}

.force-space-r {
  margin-right: 20px !important;
}

.force-space-t {
  margin-top: 20px !important;
}

.d-flex {
  display: flex;
}

// svg
.svg-defs {
  position: absolute;
  z-index: -1;
  user-select: none;
  pointer-events: none;
}

.button-inline {
  min-width: 240px !important;
  align-items: center;
  display: inline-flex !important;
  width: auto !important;
  svg {
    margin-right: 10px;
  }
}

.gray-ico {
  path {
    fill: url('#grayGradient');
  }
}

.blue-ico {
  path {
    fill: url('#blueGradient');
  }
}

.red-ico {
  path {
    fill: url('#redGradient');
  }
}

.yellow-ico {
  path {
    fill: url('#yellowGradient');
  }
}

.svgGradientTransition {
  opacity: 0;
  transition: 0.2s ease-in-out;
}

// video
.video-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; //16:9
  z-index: 9;

  iframe,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
}
.play-video {
  height: max-content;
  width: max-content;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    height: 50px;
    transition: 0.2s ease-in-out;
  }
}

// buttons
button {
  outline: none;
  border: none;
  background: transparent;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  max-width: 100%;
}

.all-btns {
  width: 250px;
  min-height: 50px;
  height: max-content;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 14px 10px;
  border: none;
  position: relative;

  svg {
    height: 20px;
    width: 20px;
    margin-right: 20px;
  }
}

.add-to-favourite-btn {
  color: $yellowd;
  background: linear-gradient(to top, #b6b6b6, white);

  &:hover,
  &:focus {
    box-shadow: inset 0px 0px 15px rgba($color: white, $alpha: 1);
  }

  &:active {
    box-shadow: inset 0px 0px 15px rgba($color: #b6b6b6, $alpha: 1);
  }
}
.favourite-big-btn {
  color: white;
  background: linear-gradient(to top, #b6300f, #fe9595);

  &:hover,
  &:focus {
    box-shadow: inset 0px 0px 15px rgba($color: #fe9595, $alpha: 1);
  }

  &:active {
    box-shadow: inset 0px 0px 15px rgba($color: #b6300f, $alpha: 1);
  }
}

.blue-btn {
  color: white;
  background: linear-gradient(to top, $blued, $bluel);

  &:hover,
  &:focus {
    color: white;
    box-shadow: inset 0px 0px 15px rgba($color: $bluel, $alpha: 1);
  }

  &:active {
    color: white;
    box-shadow: inset 0px 0px 15px rgba($color: $blued, $alpha: 1);
  }
}

.red-btn {
  color: white;
  background: linear-gradient(to top, $redd, $redl);

  &:hover,
  &:focus {
    box-shadow: inset 0px 0px 15px rgba($color: $redl, $alpha: 1);
  }

  &:active {
    box-shadow: inset 0px 0px 15px rgba($color: $redd, $alpha: 1);
  }
}

.light-gray-btn {
  color: $grayl;
  background: linear-gradient(to top, #b6b6b6, white);

  &:hover,
  &:focus {
    box-shadow: inset 0px 0px 15px rgba($color: white, $alpha: 1);
  }

  &:active {
    box-shadow: inset 0px 0px 15px rgba($color: #b6b6b6, $alpha: 1);
  }
}

.gray-btn {
  color: white;
  background: linear-gradient(to top, $grayd, $grayl);

  &:hover,
  &:focus {
    box-shadow: inset 0px 0px 15px rgba($color: $grayl, $alpha: 1);
  }

  &:active {
    box-shadow: inset 0px 0px 15px rgba($color: $grayd, $alpha: 1);
  }
}

.yellow-btn {
  color: white;
  background: linear-gradient(to top, $yellowd, $yellowl);

  &:hover,
  &:focus {
    box-shadow: inset 0px 0px 15px rgba($color: $yellowl, $alpha: 1);
  }

  &:active {
    box-shadow: inset 0px 0px 15px rgba($color: $yellowd, $alpha: 1);
  }
}

.no-bg {
  color: $grayl;
  background: transparent;

  &:hover,
  &:focus {
    box-shadow: inset 0px 0px 15px rgba($color: white, $alpha: 1);
  }

  &:active {
    box-shadow: inset 0px 0px 15px rgba($color: #b6b6b6, $alpha: 1);
  }
}

.upper {
  text-transform: uppercase;
}

/* Scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d9dde1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #525252;
}

// input
.input-class {
  height: 50px;
  width: 100%;
  @include func(font-size, 17, 15);
  font-weight: 300;
  color: $grayd;
  // text-align: center;
  outline: none;
  border: solid 1px rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;
  background: transparent;
  margin-bottom: 15px;
  transition: 0.2s ease-in-out;
  background: white;
  padding: 15px;
  text-align: left;

  &.error {
    border-color: $redd;
  }

  &.text-placeholder {
    cursor: initial;
    border-color: rgba($color: #000000, $alpha: 0.2) !important;
  }

  &:hover,
  &:focus {
    border-color: $bluel;
  }
}

textarea {
  height: 130px;
  width: 100%;
  @include func(font-size, 17, 15);
  font-weight: 300;
  color: $grayd;
  outline: none;
  border: solid 1px rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;
  background: transparent;
  margin-bottom: 15px;
  transition: 0.2s ease-in-out;
  background: white;
  padding: 15px;
  text-align: left;
  resize: none;

  &.error {
    border-color: $redd;
  }

  &.text-placeholder {
    cursor: initial;
    border-color: rgba($color: #000000, $alpha: 0.2) !important;
  }

  &:hover,
  &:focus {
    border-color: $bluel;
  }
}

.big-textarea {
  height: 260px;
}

.select-wrapper {
  width: 100%;
  height: max-content;
  position: relative;

  .select-arrow {
    width: 10px;
    position: absolute;
    // right: 5px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $grayl;
    transition: 0.2s ease-in-out;
    cursor: pointer;
  }

  &:hover,
  &:focus {
    .select-arrow {
      color: $bluel;
    }
  }
}

.select {
  height: 50px;
  width: 100%;
  @include func(font-size, 17, 15);
  font-weight: 300;
  color: $grayl;
  text-align: center;
  outline: none;
  border: solid 1px rgba($color: #000000, $alpha: 0.2);
  border-radius: 7px;
  background: transparent;
  transition: 0.2s ease-in-out;
  padding: 10px;
  padding-right: 30px;
  @include prefixer(appearance, none);
  background: white;

  &:hover,
  &:focus {
    border-color: $bluel;
  }
}

// custom checkbox
.label-class {
  display: flex;
  align-items: center;
  cursor: pointer;
  // padding: 10px 0;
  height: max-content;
  width: max-content;
  position: relative;

  span {
    margin-bottom: 0 !important;
    font-size: 15px;
    font-weight: 300;
    z-index: 3;
  }
}

.custom-checkbox {
  cursor: pointer;
  position: relative;
  height: 20px;
  width: 20px;
  margin-bottom: 0 !important;
  background-color: transparent;
  border: solid 2px $grayl;
  border-radius: 50%;
  transition: 0.1s ease-in-out;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .svg-check {
    height: 60%;
  }
}

.checkbox-original {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  height: 20px;
  width: 20px;
}

.checkbox-original:checked ~ .custom-checkbox {
  background-color: $bluel;
  border-color: $bluel;
}

// custom radio
.radio-wrapper {
  width: max-content;
  max-width: 100%;
  height: max-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  p {
    display: inline-block;
    width: calc(100% - 28px);
  }

  .radio-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
  }

  .custom-radio-check {
    height: 18px;
    width: 18px;
    border: solid 1px rgba($color: #000000, $alpha: 0.2);
    border-radius: 50%;
    margin-right: 10px;
    transition: 0.2s ease-in-out;
    background: transparent;
    position: relative;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 50%;
      background: linear-gradient(to top, $blued, $bluel);
      opacity: 0;
      transition: 0.2s ease-in-out;
    }
  }

  .radio-input:checked ~ .custom-radio-check {
    border-color: transparent;

    &::before {
      opacity: 1;
    }
  }
}

// custom select checkbox
#selectCheckbox {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;

  #displayText {
    margin: 0;
    font-weight: 300;
    text-align: start;
  }

  .options {
    position: absolute;
    left: 0;
    bottom: -5px;
    transform: translateY(100%);
    width: 100%;
    height: max-content;
    max-height: 150px;
    overflow-y: auto;
    background: white;
    border: solid 1px rgba($color: #000000, $alpha: 0.2);
    padding: 5px;
    z-index: 2;
    display: none;
    overflow: hidden;

    .label-class {
      margin-bottom: 5px;

      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }

    &.show {
      display: block;
    }
  }
}

// burger-ico
.show-burger {
  display: none !important;

  @include mq('md') {
    display: block !important;
    z-index: 99;
  }
}

.hamburger {
  padding: 15px 15px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  // background-color: white;
  border: 0;
  margin: 0;
  overflow: visible;
  z-index: 1;
  &:hover {
    opacity: 0.9;
  }

  &.is-active:hover {
    opacity: 0.9;
  }

  &.is-active .hamburger-inner,
  &.is-active .hamburger-inner::before,
  &.is-active .hamburger-inner::after {
    background-color: $bluel;
  }
}

.hamburger-box {
  width: 40px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 40px;
  height: 4px;
  background-color: $bluel;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block;
}

.hamburger-inner::before {
  top: -10px;
}

.hamburger-inner::after {
  bottom: -10px;
}

.hamburger--emphatic {
  overflow: hidden;

  & .hamburger-inner {
    transition: background-color 0.125s 0.175s ease-in;
  }

  & .hamburger-inner::before {
    left: 0;
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
      top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
  }

  & .hamburger-inner::after {
    top: 10px;
    right: 0;
    transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335),
      top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
  }

  &.is-active .hamburger-inner {
    transition-delay: 0s;
    transition-timing-function: ease-out;
    background-color: transparent !important;
  }

  &.is-active .hamburger-inner::before {
    background-color: $bluel;
    left: -80px;
    top: -80px;
    transform: translate3d(80px, 80px, 0) rotate(45deg);
    transition: left 0.125s ease-out, top 0.05s 0.125s linear,
      transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &.is-active .hamburger-inner::after {
    background-color: $bluel;
    right: -80px;
    top: -80px;
    transform: translate3d(-80px, 80px, 0) rotate(-45deg);
    transition: right 0.125s ease-out, top 0.05s 0.125s linear,
      transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
}

// toggle messages
.show-msg-btn {
  width: 100%;
  min-height: 80px;
  height: max-content;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 80px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
  cursor: pointer;
  position: relative;
  .logo-box {
    height: 60px;
    width: 60px;
    border: solid 1px rgba($color: #000000, $alpha: 0.2);
    border-radius: 50%;
    padding: 10px;
    margin-right: 20px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s ease-in-out;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
  &.unread {
    .logo-box {
      &::before {
        content: '';
        position: absolute;
        top: 5px;
        right: 2px;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background-color: $yellowd;
      }
    }
  }
  .msg-preview {
    width: calc(100% - 130px);
    height: max-content;
    display: flex;
    flex-direction: column;
    position: relative;
    h5 {
      color: $grayl;
      font-size: 15px;
      font-weight: 500;
      text-align: start;
      margin-bottom: 0;
      transition: 0.2s ease-in-out;
    }
    p {
      font-size: 14px;
      margin-bottom: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      text-align: start;
    }
  }
  .date {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
    width: 120px;
    text-align: start;
  }
  &:hover,
  &:focus,
  &.active {
    .logo-box {
      border-color: $yellowd;
    }
    .msg-preview {
      h5 {
        color: $yellowd;
      }
    }
  }
  @include mq('xl-16') {
    .msg-preview {
      width: 100%;
    }
    .date {
      width: 100%;
    }
  }
  @include mq('lg') {
    .msg-preview {
      width: calc(100% - 130px);
    }
    .date {
      width: 120px;
    }
  }
  @include mq('md') {
    .msg-preview {
      width: 100%;
    }
    .date {
      width: 100%;
    }
  }
  @include mq('sm') {
    padding-left: 0;
    padding-top: 80px;
    .logo-box {
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
    }
    .msg-preview {
      h5 {
        text-align: center;
      }
    }
    .date {
      text-align: center;
    }
  }
}

.img-date-placeholder {
  width: max-content;
  max-width: 100%;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .date {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 0;
  }
  .img-box {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.sender {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  p {
    margin-bottom: 10px;
  }
  .img-box {
    border: solid 1px rgba($color: #000000, $alpha: 0.2);
    margin-right: 10px;
  }
  img {
    object-position: center;
    object-fit: contain;
  }
}

.reviever {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 20px;
  p {
    margin-bottom: 10px;
    text-align: end;
    font-weight: 300;
  }
  .img-box {
    border: solid 1px rgba($color: #000000, $alpha: 0.2);
    margin-right: 0;
    margin-left: 10px;
    order: 1;
  }
  img {
    object-position: center;
    object-fit: cover;
  }
}

.messages-preview-all {
  height: 100%;
  width: 100%;
  overflow: auto;
  padding-right: 10px;
}

.conversation {
  width: 100%;
  height: calc(100% - 110px);
  flex-grow: 1;
  overflow: auto;
  padding-right: 10px;
}

.write-message-wrapper {
  width: 100%;
  height: max-content;
  border-top: solid 1px rgba($color: #000000, $alpha: 0.2);
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: linear-gradient(
    to top,
    rgb(241, 244, 247),
    rgba(241, 244, 247, 0)
  );
  padding: 0 20px;
  margin-top: 10px;
  min-height: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  .img-wrapper {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    margin-right: 15px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  textarea {
    width: calc(100% - 110px);
    height: max-content;
    resize: vertical;
    font-size: 15px;
    margin-bottom: 0;
    padding: 5px;
  }
}

// p as textarea
.textarea-message {
  height: max-content;
  width: calc(100% - 110px);
  font-weight: 300;
  color: $grayd;
  outline: none;
  border: solid 1px rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;
  transition: 0.2s ease-in-out;
  background: white;
  padding: 10px;
  font-size: 15px;
  max-height: 76px;
  overflow: auto;
  margin-bottom: 0 !important;

  &.error {
    border-color: $redd;
  }

  &.text-placeholder {
    cursor: initial;
    border-color: rgba($color: #000000, $alpha: 0.2) !important;
  }

  &:hover,
  &:focus {
    border-color: $bluel;
  }

  &:active,
  &:not(:empty) {
    .textarea-message-placeholder {
      display: none;
    }
  }
}

.send-btn {
  height: 40px;
  width: 40px;
  margin-left: 10px;
  outline: none;
  border: none;
  border-radius: 10px;
  color: $grayd;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 70%;
  }
  &:hover,
  &:focus {
    color: $bluel;
  }
  &:active {
    box-shadow: inset 0 0 10px rgba($color: #000, $alpha: 0.2);
  }
}

.input-class-2 {
  height: 50px;
  @include func(font-size, 17, 15);
  font-weight: 300;
  color: $grayd;
  // text-align: center;
  outline: none;
  border: solid 1px rgba($color: #000000, $alpha: 0.2);
  border-radius: 10px;
  background: transparent;
  margin-bottom: 15px;
  transition: 0.2s ease-in-out;
  background: white;
  padding: 15px;
  text-align: left;

  &.error {
    border-color: $redd;
  }

  &.text-placeholder {
    cursor: initial;
    border-color: rgba($color: #000000, $alpha: 0.2) !important;
  }

  &:hover,
  &:focus {
    border-color: $bluel;
  }
}

.admin-logo-box img {
  object-fit: contain;
  object-position: left;
}

.public-DraftStyleDefault-ltr {
  text-align: unset !important;
}
.custom-burger {
  padding: 18.5px 12px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 20px rgba($color: #000000, $alpha: 0.2);
}

.custom-burger .hamburger-inner::before {
  background-color: transparent;
  background: linear-gradient(to top, #278ff0, #2a99ff);
}

.custom-burger .hamburger-inner {
  background-color: #1e75c8;
}

.custom-burger .hamburger-inner::after {
  background-color: transparent;
  background: linear-gradient(to top, #03254c, #104c88);
}

.mr-0 {
  margin-right: 0 !important;
}
