@import "../../scss/breakpoints";
@import "../../scss/colors";

.cv-dashboard-settings-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
}

.cv-dashboard-settings-upload {
  margin-bottom: 20px;
  @include md {
    margin: 0px auto 20px;
  }
}

.cv-dashboard-settings-content {
  width: 100%;
  display: flex;
  .cv-dashboard-settings-content-side {
    width: 100%;
    &:not(:last-child) {
      margin-right: 20px;
      @include md {
        margin-right: 0px;
        margin-bottom: 20px;
      }
    }
  }
  @include md {
    flex-wrap: wrap;
  }
}

.cv-dashboard-settings-select-wrap {
  display: flex;
  justify-content: space-between;
  div {
    width: 98%;
  }
  @include sm {
    display: block;
    div {
      width: 100%;
    }
  }
}

.cv-dashboard-settings-avatar-wrap {
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  div {
    color: rgba($black, 0.6);
  }
  @include md {
    display: block;
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
}

.cv-dashboard-settings-buttons {
  @include sm {
    width: 100%;
    margin-bottom: 20px !important;
  }
}

.cv-settings-notifications-locations-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 10px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: inset 0px 0px 4px 2px rgba($black, 0.1);
}

.cv-dashboard-page-scrollable {
  width: calc(100% + 40px);
  // height: calc(100% + 40px);
  // overflow-y: auto;
  margin: -20px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: flex-start;
  &::-webkit-scrollbar {
    width: 0px;
    opacity: 0;
  }
  @include xl {
    padding: 15px;
  }
}

.cv-data-display-label {
  color: rgba($black, 0.6);
  margin-bottom: 10px;
  font-size: 15px;
  span {
    color: $primary_second;
    margin-left: 5px;
  }
}

.cv-dashboard-profile-col-double {
  grid-column-start: 1;
  grid-column-end: 3;
  @include md {
    grid-column-end: 2;
  }
}

.cv-settings-input-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
}

.cv-settings-button {
  width: 25%;
  margin-right: 10px;
}
