.profile-row {
  display: flex;
  width: 100%;
}

.profile-column {
  width: 48%;
}

.profile-box-title {
  margin-bottom: 0px !important;
}

.profile-box-body {
  padding: 15px;
  width: 100%;
}

.profile-input-wrapper {
  p {
    margin-bottom: 10px;
  }
}

.profile-label,
.profile-label p {
  font-weight: 500;
}
