#mainContent {
  .container-flex {
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .container-header {
      width: 100%;
      height: max-content;
      position: relative;
      padding: 20px;
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
      transition: 0.2s ease-in-out;
      min-height: 70px;
      h4 {
        text-transform: uppercase;
        @include func(font-size, 18, 16);
        color: $grayl;
        margin-bottom: 5px;
      }
      .info-btn {
        height: 20px;
        width: 20px;
        color: $grayl;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        transition: 0.2s ease-in-out;

        .info-content {
          width: 350px;
          height: max-content;
          padding: 10px;
          background: white;
          border-radius: 10px;
          position: absolute;
          top: -20px;
          right: -20px;
          transform: translateY(-100%);
          display: flex;
          flex-direction: column;
          box-shadow: 0 10px 10px rgba($color: #000000, $alpha: 0.2);
          transition: 0.2s ease-in-out;
          opacity: 0;
          @include prefixer(pointer-events, none);
          p {
            color: $grayl;
          }
          &::before {
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            right: 20px;
            bottom: -15px;
            background: white;
            clip-path: polygon(50% 100%, 0 0, 100% 0);
          }
          p {
            text-align: initial;
            margin-bottom: 10px;
          }
          a {
            text-align: initial;
            margin-bottom: 5px;
          }

          @include mq('sm-4') {
            width: calc(100vw - 70px);
            min-width: 250px;
          }
        }

        svg {
          height: 20px;
        }
        &:hover,
        &:focus {
          color: $yellowd;
          .info-content {
            pointer-events: initial;
            opacity: 1;
          }
        }
      }
    }
    .container-header-wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0;
      padding-top: 10px;
      h2 {
        width: max-content;
        margin-bottom: 10px;
        margin-right: 20px;
        @include mq('sm') {
          margin-right: 0;
        }
      }
      .search-box-wrapper {
        width: max-content;
        margin-bottom: 10px;
        @include mq('sm') {
          width: 100%;
        }
      }
      .wrapper {
        margin-bottom: 0;
        @include mq('sm') {
          width: 100%;
        }
      }
      .search-wrapper {
        width: max-content;
        input {
          width: 320px;
        }
        @include mq('sm') {
          width: 100%;
          input {
            width: 100%;
          }
        }
      }
    }
    .container-body {
      width: 100%;
      height: max-content;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px;
    }
    .container-full {
      width: 100%;
      height: auto;
      position: relative;
      background: linear-gradient(to top, $bg, white);
      margin-bottom: 20px;
      border-top: none;
    }
    .container-half {
      width: 48%;
      height: auto;
      position: relative;
      background: linear-gradient(to top, $bg, white);
      border-radius: 20px;
      margin-bottom: 20px;
      @include mq('lg') {
        &.w-lg-100 {
          width: 100%;
        }
      }
      @include mq('md') {
        width: 100%;
      }
    }
    .container-third {
      width: 31%;
      height: auto;
      position: relative;
      background: linear-gradient(to top, $bg, white);
      border-radius: 20px;
      margin-bottom: 20px;
      @include mq('xl-14') {
        width: 48%;
      }
      @include mq('md') {
        width: 100%;
      }
    }
    .height-550 {
      height: 550px !important;
    }

    .input-wrapper {
      width: max-content;
      height: max-content;
      position: relative;
      margin-bottom: 20px;
      p {
        margin-bottom: 10px;
        span {
          color: $yellowd;
        }
      }
      input,
      textarea {
        margin-bottom: 0;
      }

      &.input-wrapper-border {
        padding-bottom: 10px;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
      }
    }
    .wrapper-w-100 {
      width: 100%;
    }
    .wrapper-w-50 {
      width: 48%;
      button {
        width: 100%;
      }
      @include mq('xl-14') {
        width: 100%;
      }
      @include mq('lg') {
        &.wrapper-w-50-lg {
          width: 48%;
        }
      }
      @include mq('md') {
        width: 48%;
        &.wrapper-w-50-lg {
          width: 100%;
        }
      }
      @include mq('sm') {
        width: 100%;
      }
    }
    .wrapper-position {
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .current-package {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      .text-wrapper {
        height: max-content;
        width: max-content;
        max-width: 100%;
        position: relative;
        p {
          margin-bottom: 5px;
        }
      }
      @include mq('xl-16') {
        button {
          padding-left: 20px;
          padding-right: 20px;
          width: max-content;
        }
      }
      @include mq('xl-14') {
        .text-wrapper {
          width: 100%;
          margin-bottom: 20px;
        }
        button {
          width: 100%;
        }
      }
      @include mq('md') {
        padding: 20px 0 20px 20px;
        .text-wrapper {
          width: max-content;
          max-width: 48%;
        }
        button {
          width: calc(48% + 10px);
        }
      }
      @include mq('sm') {
        padding: 20px;
        .text-wrapper {
          width: 100%;
          max-width: 100%;
        }
        button {
          width: 100%;
        }
      }
    }
    .btns-container {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      padding: 20px 0 20px 20px;
      background: transparent;
      position: relative;
      button {
        &:nth-last-child(1) {
          margin-left: 20px;
        }
      }
      @include mq('xl-16') {
        justify-content: space-between;
        button {
          width: 48%;
          &:nth-last-child(1) {
            margin-left: 0;
          }
        }
      }
      @include mq('xl-14') {
        button {
          width: 100%;
          &:nth-child(1) {
            margin-bottom: 20px;
          }
        }
      }
      @include mq('md') {
        width: 100%;
        padding: 20px 0;
        button {
          &:nth-child(1) {
            margin-bottom: 0;
          }
          width: 48%;
        }
      }
      @include mq('sm') {
        width: 100%;
        padding: 20px;
        button {
          &:nth-child(1) {
            margin-bottom: 20px;
          }
          width: 100%;
        }
      }
    }

    .package-box {
      text-align: initial;
      .container-header {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      h5 {
        font-weight: normal;
        color: $grayl;
        width: max-content;
        max-width: 100%;
        margin-right: 20px;
      }

      .price-info {
        p {
          font-weight: 300;
          line-height: 1.5em;
        }
        h5 {
          margin-bottom: 20px;
        }
      }
      .package-info-box {
        width: 100%;
        height: max-content;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        .price {
          @include func(font-size, 30, 20);
          color: $yellowd;
        }
      }
      .w-100 {
        width: 100%;
      }

      &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 20px;
        transition: 0.2s ease-in-out;
        background: linear-gradient(to top, $blued, $bluel);
        opacity: 0;
      }
      &.active {
        h2,
        h4,
        h5,
        p {
          color: white;
        }
        .container-header,
        .package-info-box {
          border-color: white;
        }
        .info-btn {
          color: white;
          &:hover,
          &:focus {
            color: $yellowd;
          }
        }
        &::before {
          opacity: 1;
        }
      }
    }

    .package-details-container {
      width: 58%;
      height: max-content;
      position: relative;
      background: linear-gradient(to top, $bg, white);
      border-radius: 20px;
      margin-bottom: 20px;
      @include mq('xl-14') {
        width: 48%;
      }
      @include mq('md') {
        width: 100%;
      }
    }
    .payment-container {
      width: 38%;
      height: max-content;
      position: relative;
      border-radius: 20px;
      margin-bottom: 20px;
      background: transparent;

      h2 {
        text-transform: initial;
      }
      .container-header {
        border: none;
      }
      .payment-card-wrapper {
        width: 48%;
        height: 120px;
        position: relative;
        margin-bottom: 20px;
        @include mq('sm-4') {
          width: 100%;
        }
      }
      .payment-card {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 20px;
        border-radius: 10px;
        background: white;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
        position: relative;
        cursor: pointer;
        border: solid 1px transparent;
        transition: 0.2s;
        color: $grayl;
        p {
          color: inherit;
          width: calc(100% - 50px);
        }
        svg {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }
        &:hover,
        &:focus,
        &.active {
          border-color: $bluel;
          box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0);
          color: $bluel;
        }
      }
      .payment-card-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        z-index: -1;
      }
      .payment-card-input:checked ~ .payment-card {
        border-color: $bluel;
        color: $bluel;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0);
      }
      @include mq('xl-14') {
        width: 48%;
      }
      @include mq('md') {
        width: 100%;
      }
    }
    .subscription-box {
      width: 100%;
      height: max-content;
      margin-bottom: 20px;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      .subscription-wrapper {
        width: max-content;
        height: max-content;
        margin-right: 20px;
        margin-bottom: 20px;
        p {
          display: block;
          padding-left: 25px;
          position: relative;
          cursor: pointer;

          &::before {
            content: '';
            height: 20px;
            width: 20px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background: white;
            border-radius: 50%;
            border: solid 2px $bluel;
            transition: 0.2s ease-in-out;
          }
        }
        .subscription-input {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          z-index: -1;
        }

        .subscription-input:checked ~ p {
          &::before {
            background: $bluel;
          }
        }

        &:nth-last-child(1) {
          margin-right: 0;
        }
      }
    }
  }
}

.add-ad-question-single-preview {
  color: #666;
  margin-bottom: 20px;
  span {
    color: $bluel;
  }
}

.add-ad-row-actions-icon {
  width: 40px;
  height: 40px;
  font-size: 30px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  span {
    margin-bottom: 0px !important;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
}

.upload-video-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 500;
}

.add-ad-row-info {
  color: #666;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 10px;
  &:not(:last-child) {
    margin-bottom: 20px;
    border-bottom: 1px solid #00000020;
  }
  & > span {
    color: #999;
    font-weight: 200;
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.add-ad-image-holder {
  width: 160px;
  height: 160px;
  border: 2px solid #00000020;
  border-radius: 50%;
  overflow: hidden;
  padding: 10px;
  @media screen and (max-width: 600px) {
    margin: 0px auto;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.edit-ad-loading {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 20px;
}

.add-ads-no-questions {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background: linear-gradient(to top, rgba(107, 107, 107, 0.1), white);
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
  min-height: 150px;
  margin-bottom: 20px;
  svg {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    color: #2897fb;
  }
}

.btn-position-right {
  margin: 20px 20px 0 auto;
}

.add-ad-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
  }
}

.add-ad-half {
  width: 100%;
  &:not(:last-child) {
    margin-right: 20px;
    @media screen and (max-width: 600px) {
      margin-right: 0px;
    }
  }
}

.add-ad-questions-main {
  width: 100%;
  h4 {
    color: $bluel;
    margin-bottom: 20px;
  }
  .add-ad-questions {
    width: 100%;
  }
}

.preview-ad-actions {
  border-top: 1px solid #00000020;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  .preview-ad-actions-left {
    display: flex;
  }
  .preview-ad-actions-right {
    display: flex;
    .button-inline:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.company-background-holder {
  width: calc(100% + 40px);
  position: relative;
  padding: 20px 20px;
  margin: -20px -20px 20px;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
  border-bottom: 1px solid #00000020;
}

.company-link {
  font-size: 16px !important;
  font-weight: 400;
}

.company-background {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  &::after {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(to top, #00000090, #00000020);
    content: '';
    left: 0px;
    top: 0px;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.add-ad-actions {
  border-top: 1px solid #00000020;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  .button-inline:not(:last-child) {
    margin-right: 10px;
  }
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    .button-inline {
      width: 100% !important;
      margin-right: 0px !important;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}

// add ad
.add-ad-container {
  width: 100%;
  height: max-content;
  position: relative;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to top, $bg, white);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
  .title {
    width: 100%;
    height: max-content;
    display: block;
    padding: 20px;
    border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
  }
  .add-ad-wrapper {
    width: 100%;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 20px;
    .number-of-characters {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      font-size: 12px;
      color: #666;
      margin-top: -10px;
      &.number-of-characters-error {
        color: #ff4444;
      }
    }
    @include mq('sm') {
      padding: 20px 10px;
    }
  }
  .left-side {
    width: 43%;
    height: max-content;
    position: relative;
    justify-content: space-between;
    p {
      font-weight: 300;
    }
  }
  .right-side {
    width: 55%;
    height: max-content;
    position: relative;
    justify-content: space-between;
  }
  .questions-side {
    width: 100%;
    height: max-content;
    position: relative;
    .question-wrap {
      @include mq('md') {
        display: flex;
        flex-wrap: wrap;
        input {
          width: 100%;
          margin-bottom: 10px;
        }
        .add-remove-btn {
          &:nth-child(2) {
            margin-left: auto;
          }
        }
      }
    }
  }
  .btns-container {
    width: 100%;
    height: max-content;
    position: relative;
  }
  @include mq('xl-14') {
    .left-side,
    .right-side {
      width: 100%;
      margin-bottom: 30px;
    }
  }
  @include mq('md') {
    .box-50 {
      width: 100%;
    }
  }
  @include mq('sm') {
    .w-half {
      width: 100%;
    }
  }
}
.ad-wrapper-header {
  width: 100%;
  height: max-content;
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  h4 {
    @include func(font-size, 20, 17);
    font-weight: 500;
    color: $bluel;
    margin-right: 20px;
  }
  @include mq('xl-14') {
    h4 {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}
.about-company-more-details {
  width: 100%;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .quater-box {
    width: 23%;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    p {
      margin-bottom: 10px;
    }
    input {
      margin-bottom: 0;
    }

    @include mq('xl-14') {
      width: 46%;
    }
    @include mq('sm') {
      width: 100%;
    }
  }
  .radio-wrapper-box {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: auto;
    p {
      margin-bottom: 0;
    }
    .radio-wrapper {
      &:nth-child(1) {
        margin-bottom: 5px;
      }
    }
  }
}

.text-area-wrapper {
  width: 100%;
  height: max-content;
  position: relative;
  textarea {
    padding-bottom: 35px;
  }
  .bold-btn {
    height: 20px;
    width: 20px;
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
    color: $grayl;
    &:hover,
    &:focus,
    &.active {
      color: $grayd;
    }
    position: absolute;
    left: 10px;
    bottom: 30px;
    &::before {
      content: '/';
      font-size: 18px;
      line-height: inherit;
      color: $grayl;
      font-weight: 300;
      position: absolute;
      top: 0;
      right: -10px;
      width: 10px;
    }
  }
  .underline-btn {
    height: 20px;
    width: 20px;
    font-size: 19px;
    line-height: 20px;
    font-weight: normal;
    color: $grayl;
    text-decoration: underline;

    &:hover,
    &:focus,
    &.active {
      color: $grayd;
    }

    position: absolute;
    left: 40px;
    bottom: 30px;
  }
}
.job-desc-textarea {
  height: 350px;
  overflow-y: scroll;
}
.btns-container {
  justify-content: flex-end;
  padding-top: 20px;
  // border-top: solid 1px rgba($color: #000000, $alpha: 0.2);
  margin-top: 20px;
  button {
    margin-left: 20px;
    &:nth-child(1) {
      margin-left: 0;
    }
  }
  @include mq('lg') {
    justify-content: space-between;
    button {
      width: 31%;
      margin-left: 0;
    }
  }
  @include mq('md') {
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    button {
      width: 60%;
      margin-bottom: 20px;
    }
  }
  @include mq('sm') {
    button {
      width: 100%;
    }
  }
}
.container-body {
  padding: 20px;
  .p-title {
    margin-bottom: 40px;
  }
  p {
    margin-bottom: 20px;
  }
  ul {
    list-style: none;
    width: 35%;
    padding-right: 20px;
    margin-right: 20px;
    border-right: solid 1px rgba($color: #000000, $alpha: 0.2);
    height: auto;
    &.last-ul {
      border-right: none;
    }
    li {
      color: $grayl;
      @include func(font-size, 16, 14);
      font-weight: normal;
      margin-bottom: 10px;
    }

    @include mq('xl-14') {
      width: 48%;
      margin-right: 2%;
      margin-bottom: 20px;
      &.last-ul {
        margin-right: 0;
        margin-left: 2%;
      }
    }
    @include mq('md') {
      width: 100%;
      margin-right: 0;
      &.last-ul {
        margin-left: 0;
      }
    }
  }
  @include mq('sm-4') {
    button {
      width: 100%;
    }
  }
}

// uplaoded file box
.preview-logo-box {
  display: none;
}
.preview-video-box {
  display: none;
}
.uploaded {
  // hide
  #uploadLogo {
    display: none;
  }
  #uploadVideo {
    display: none;
  }

  // show
  .preview-logo-box {
    display: block;
    width: 70px;
    height: 70px;
    position: relative;
    border-radius: 50%;
    border: solid 1px rgba($color: #000000, $alpha: 0.2);
    background: white;
    margin-top: 20px;

    img {
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: contain;
      border-radius: 50%;
    }
    #editLogo {
      height: 35px;
      width: 35px !important;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      background: linear-gradient(to top, $blued, $bluel);
      position: absolute;
      right: -10px;
      top: -10px;
      svg {
        height: 15px;
      }
    }
  }
  .preview-video-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 100px;
    max-width: 100%;
    position: relative;
    margin-top: 10px;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 10px;
      object-position: center;
      object-fit: cover;
      filter: brightness(0.5);
    }
    #editVideo {
      height: 35px;
      width: 35px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: linear-gradient(to top, $yellowd, $yellowl);
      color: white;
      z-index: 2;
      svg {
        height: 15px;
      }
    }
  }
}

.deactivate-container {
  width: 100%;
  height: max-content;
  border-top: solid 1px rgba($color: #000000, $alpha: 0.2);
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  @include mq('lg') {
    button {
      width: calc(48% - 20px);
    }
  }
  @include mq('md') {
    button {
      width: 48%;
    }
  }
  @include mq('sm') {
    padding: 20px;
    button {
      width: 100%;
    }
  }
}

.text-normal {
  p {
    font-weight: 300 !important;
  }
}
.empty-circle {
  height: 18px;
  width: 18px;
  border: solid 1px white;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
  &:hover {
    background: white;
  }
  // &:focus {
  //   background: white;
  // }
  &.fill {
    background: white;
  }
}
.btn-circle {
  height: 18px !important;
  width: 18px !important;
  border-radius: 50%;
  border: solid 1px $grayl;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background: linear-gradient(to top, $blued, $bluel);
    opacity: 0;
    transition: 0.2s ease-in-out;
  }
  &:hover {
    border-color: transparent;
    &::before {
      opacity: 1;
    }
  }
}
.gray-circle {
  filter: grayscale(1);
}

// profil kandidata
.candidate-profile-view-container {
  height: max-content;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  .candidate-header {
    width: 100%;
    height: max-content;
    position: relative;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .candidate-profile-img {
      height: 180px;
      width: 180px;
      border-radius: 50%;
      margin-right: 20px;
      object-position: center;
      object-fit: cover;
    }
    .header-wrapper {
      width: calc(100% - 220px);
      height: 180px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      border-top: solid 1px rgba($color: #000000, $alpha: 0.2);
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);

      h3 {
        @include func(font-size, 30, 20);
        line-height: 1.1em;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .title-box {
        width: max-content;
        height: max-content;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
        h3 {
          letter-spacing: 2px;
        }
      }

      .btn-wrapper {
        height: max-content;
        width: max-content;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-left: 0;
        position: relative;
        button {
          min-width: max-content;
        }
      }
    }

    @include mq('sm') {
      .candidate-profile-img {
        width: 150px;
        height: 150px;
      }
      .header-wrapper {
        width: calc(100% - 160px);
        height: max-content;
        border: none;
        .btn-wrapper {
          display: none;
        }
      }
    }
  }

  .candidate-details-container {
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-left: 20px;
    margin-top: 40px;

    .candidate-info-list {
      width: 48%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      list-style: none;
      li {
        width: 48%;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        padding-bottom: 25px;
        margin-bottom: 25px;
        position: relative;
        padding-right: 35px;
        p {
          &:nth-child(1) {
            font-weight: 300;
            margin-bottom: 5px;
          }
        }
        &.w-100 {
          width: 100%;
        }
      }
      .dwn-link {
        position: absolute;
        top: calc(50% - 20px);
        right: 0;
        color: rgba($color: #000000, $alpha: 0.2);
        svg {
          height: 20px;
          margin: 0;
        }
        &:hover,
        &:focus {
          color: $bluel;
        }
      }
      @include mq('xl-14') {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          width: 48%;
        }
      }
      @include mq('sm') {
        li {
          width: 100%;
        }
      }
    }
    .candidate-video-side {
      width: 48%;
      height: max-content;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      .video-header {
        width: 100%;
        height: max-content;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        p {
          margin-bottom: 20px;
          @include func(font-size, 18, 16);
        }
        a {
          margin-bottom: 20px;
          text-transform: uppercase;
          font-weight: normal;
        }
      }
      .video-wrapper {
        max-width: 900px;
        position: relative;
        margin-bottom: 30px;
        video {
          width: 100%;
          height: 100%;
          position: absolute;
          object-fit: cover;
          border-radius: 20px;
        }
        .play-video {
          height: max-content;
          width: max-content;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 3;
          svg {
            height: 70px;
          }
        }
      }
      .about-me {
        width: 100%;
        height: max-content;
        position: relative;
      }

      @include mq('xl-14') {
        width: 100%;
      }
    }
    @include mq('xl') {
      width: 100%;
      margin-top: 30px;
    }
  }

  .download-video-link {
    @include mq('sm') {
      color: rgba($color: #000000, $alpha: 0.2);
      span {
        display: none;
      }
      svg {
        height: 20px;
        margin: 0;
      }
      &:hover,
      &:focus {
        color: $bluel;
      }
    }
  }
}

.mobile-btns {
  display: none;
  @include mq('sm') {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: max-content;
    position: relative;
    margin-top: 40px;
    button {
      width: 100%;
      margin-bottom: 20px;
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
  }
}

.progress-wrapper {
  width: 100%;
  height: max-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin-bottom: 0 !important;
  }
  progress {
    width: calc(100% - 60px);
  }
}
progress {
  border-radius: 5px;
  width: 100%;
  height: 10px;
  &::-webkit-progress-bar {
    background: rgba($color: #000000, $alpha: 0.2);
    border-radius: 5px;
  }
  &::-webkit-progress-value {
    border-radius: 5px;
    background: linear-gradient(to right, $yellowd, $yellowl);
  }
}

.add-remove-btn {
  height: 50px;
  width: 50px;
  border-radius: 10px;
  border: solid 1px rgba($color: #000000, $alpha: 0.2);
  background: white;
  color: rgba($color: #000000, $alpha: 0.2);
  margin-left: 10px;
  svg {
    height: 30px;
  }
  &:hover,
  &:focus {
    color: $bluel;
    border-color: $bluel;
  }
}

details {
  width: 100%;
  overflow: hidden;
  border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
  transition: 0.2s ease-in-out;

  summary {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: max-content;
    position: relative;
    cursor: pointer;
    padding: 20px 0;
    outline: none;
    border: none;
    color: $grayl;

    &::-webkit-details-marker {
      display: none;
    }

    svg {
      width: 15px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      transition: 0.2s ease-in-out;
    }
  }

  &.modal-details {
    border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);

    p {
      margin-bottom: 5px;
    }
    .detail-box {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
    }
  }

  &[open] {
    padding-bottom: 20px;
    summary {
      color: $bluel;
      svg {
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  &:hover,
  &:focus {
    .bold {
      color: $bluel;
    }
  }
}

@include mq('sm-4') {
  .box-50 {
    width: 100%;
  }
  .w-half {
    width: 100%;
    margin-bottom: 10px;
  }
  .w-third {
    width: 48%;
  }
  .w-quater {
    order: 1;
    width: 48%;
    margin-bottom: 10px;
  }
}

.logo-placeholder {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  border: solid 1px rgba($color: #000000, $alpha: 0.2);
  position: relative;
  overflow: hidden;
  background: white;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 90%;
    height: 90%;
    object-position: center;
    object-fit: contain;
  }

  @include mq('xl') {
    width: 150px;
    height: 150px;
  }
}

.custom-company-name-txt {
  margin-bottom: 20px !important;
}

.uploaded .preview-logo-box {
  display: block;
  width: 100px;
  height: 100px;
  position: relative;
  margin-top: 20px;
  border: solid 1px rgba(0, 0, 0, 0);
}

#mainContent .btns-container.cp-edit-btn {
  display: none;
}

@media only screen and (max-width: 1400px) {
  #mainContent header .btn-wrapper.cp-d-edit-btn {
    width: max-content;
    button {
      margin-right: 0 !important;
    }
  }
}

@media only screen and (max-width: 767px) {
  #mainContent .cp-d-edit-btn {
    display: none !important;
  }
  #mainContent .btns-container.cp-edit-btn {
    display: flex;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  #mainContent .btns-container.deact-btn {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 576px) {
  #mainContent .btns-container.deact-btn {
    margin-top: 0 !important;
  }
  #mainContent .btns-container.cp-edit-btn {
    padding-left: 0;
    padding-right: 0;
  }
  #mainContent .btns-container.deact-btn {
    padding-left: 0;
    padding-right: 0;
  }
}

.questions-side-legend {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.questions-side-legend .legend-item {
  font-size: 15px;
  font-weight: 300 !important;
  display: flex;
  align-items: center;
  color: #353535;
  margin-right: 30px;
  margin-bottom: 20px;
}

.questions-side-legend .legend-item svg {
  height: 18px;
  width: 18px;
  margin-right: 10px;
}

.questions-side-legend .legend-item svg path {
  fill: #6b6b6b;
}

.questions-side-legend .legend-item span {
  font-size: 15px;
  font-weight: 300 !important;
}

.candidate-profile-view-container
  .candidate-details-container
  .candidate-video-side
  .rec-box {
  height: auto;
}
