@import "../../../scss/colors";
@import "../../../scss/breakpoints";

.cv-dashtitle-main {
  display: flex;
  align-items: flex-end;
  margin-bottom: 20px;
  order: 0;
  @include md {
    width: 100%;
  }
}

.cv-dashtitle-left {
  h3 {
    font-size: 36px;
    color: $secondary;
    font-weight: 500;
    margin-bottom: 10px;

    @include md {
      font-size: 24px;
    }
  }
  p {
    color: rgba($black, 0.7);

    @include md {
      font-size: 14px;
    }
  }
}

.cv-dashtitle-actions {
  display: flex;
  order: 1;
  align-items: center;
  & > * {
    @include md {
      width: 100%;
    }
    &:not(:last-child) {
      margin-right: 10px;
      @include md {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }
  }
  @include md {
    order: 3;
    width: 100%;
    margin-top: 20px;
  }
  @include sm {
    flex-wrap: wrap;
    & > button,
    & > a {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
