// modals
.modal-center-text {
  text-align: center;
  padding: 10px 0px;
  margin-bottom: 30px;
}
.modal-bg {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba($color: #000000, $alpha: 0.2);
  backdrop-filter: blur(10px);
  display: none;
  align-items: center;
  justify-content: center;
  z-index: 9999;

  .bg-blue {
    background: linear-gradient(to top, #eff3f6, #fff) !important;
  }
  .bg-red {
    background: linear-gradient(to top, #ffdcaf, #fff) !important;
  }

  .modal {
    width: 90%;
    max-width: 570px;
    height: max-content;
    position: relative;
    background: linear-gradient(to top, $bg, white);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
    margin: auto;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    .modal-header {
      width: 100%;
      height: max-content;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 20px;
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);

      h5 {
        @include func(font-size, 21, 17);
        font-weight: 500;
        color: $bluel;
        text-transform: uppercase;
        word-break: break-all;
        padding-right: 10px;
        margin-bottom: 5px;
      }

      p {
        width: 100%;
      }

      .close-btn {
        height: 30px;
        width: 30px;
        color: rgba($color: #000000, $alpha: 0.2);
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          height: 20px;
        }

        &:hover,
        &:focus {
          color: $bluel;
        }
      }
    }

    .modal-body {
      width: 100%;
      height: max-content;
      max-height: calc(100vh - 80px);
      overflow: auto;
      position: relative;
      padding: 20px;
      display: flex;
      flex-wrap: wrap;

      // confirm ad modal
      .ad-details-box {
        background: transparent;
        box-shadow: none;
        border-radius: none;
      }
      .ad-body {
        padding: 20px 0;
      }
      .company-details {
        h2 {
          margin-bottom: 20px;
          text-transform: initial;
        }
        @include mq('lg') {
          width: 100%;
        }
        @include mq('md') {
          order: 2;
        }
      }
      .company-description {
        height: auto;
        display: flex;
        flex-direction: column;
        @include mq('lg') {
          width: 100%;
        }
        @include mq('md') {
          order: 3;
        }
      }
      .ad-modal-btns-wrapper {
        width: 100%;
        height: max-content;
        margin-top: auto;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        button {
          &:nth-last-child(1) {
            margin-left: 20px;
          }
        }
        @include mq('xl') {
          justify-content: space-between;
          button {
            width: 48% !important;
            &:nth-last-child(1) {
              margin-left: 0;
            }
          }
        }
        @include mq('lg') {
          margin-top: 30px;
        }
        @include mq('sm') {
          button {
            width: 100%;
            margin-bottom: 20px;
          }
        }
      }
      .video-footer {
        width: 100%;
        height: max-content;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 20px;
        @include mq('sm') {
          flex-direction: column;
          a {
            margin-bottom: 10px;
          }
          button {
            width: 100%;
          }
        }
      }
      .msg-body-wrapper {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: column;
        p {
          margin-bottom: 20px;
        }
        h5 {
          font-weight: 500;
          font-size: 20px;
          color: $grayl;
          margin-bottom: 20px;
        }
        .box {
          p {
            margin-bottom: 10px;
          }
        }
      }
      .msg-btn-pos {
        margin-left: auto;
        margin-top: 40px;
        @include mq('sm') {
          width: 100%;
        }
      }
    }

    .box {
      height: max-content;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;

      p {
        font-weight: 500;
        margin-bottom: 10px;
      }

      input {
        margin-bottom: 0;
      }
    }

    .box-100 {
      width: 100%;
    }

    .box-50 {
      width: 48%;
    }

    .box-divider {
      width: 100%;
      height: 1px;
      background: rgba($color: #000000, $alpha: 0.2);
      margin-bottom: 20px;
    }

    .box-wrap {
      width: 100%;
      height: max-content;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      position: relative;
    }

    .check-wrapper {
      height: max-content;
      width: max-content;
      position: relative;
      display: flex;
      align-items: center;

      input {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        border: solid 1px rgba($color: #000000, $alpha: 0.2);
        background: transparent;
        margin-right: 15px;
      }

      p {
        font-weight: 300;
      }
    }

    .filters-wrap {
      width: 100%;
      height: max-content;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin: 20px 0 30px 0;

      p {
        margin-bottom: 10px;
        // margin-right: 20px;
      }

      .radio-wrapper {
        margin-bottom: 10px;
        margin-right: 10px;

        p {
          margin-bottom: 0;
        }
      }
    }

    .w-full {
      width: 100%;
    }

    .w-full-btn {
      width: calc(100% - 60px);
    }

    .w-half {
      width: 48%;
    }

    .w-third {
      width: 31%;
    }

    .w-quater {
      width: 22%;
    }

    .mb-10 {
      margin-bottom: 10px;
    }

    .mb-20 {
      margin-bottom: 20px;
    }

    .mb-40 {
      margin-bottom: 40px;
    }

    .skill-box {
      width: 100%;
      height: max-content;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-end;
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
      margin-bottom: 20px;

      button {
        width: 10%;
        height: 50px;
        position: relative;
        border: solid 1px rgba($color: #000000, $alpha: 0.2);
        background: white;
        color: rgba($color: #000000, $alpha: 0.2);
        margin-bottom: 20px;
        border-radius: 7px;

        svg {
          height: 50%;
        }

        &:hover,
        &:focus {
          color: $bluel;
        }

        &:active {
          color: $blued;
        }
      }

      @include mq('sm') {
        button {
          width: 50px;
        }
      }

      @include mq('sm-4') {
        .w-tird {
          width: 50%;
        }
      }
    }

    .sides {
      width: 48%;
      height: max-content;
      position: relative;
      display: flex;
      flex-direction: column;

      .fill-box {
        padding: 20px 0;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
      }

      .remove-icon {
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba($color: #000000, $alpha: 0.2);
        &:hover,
        &:focus {
          color: $bluel;
        }
        &:active {
          color: $blued;
        }
        svg {
          height: 80%;
        }
      }

      .w-100 {
        width: 100%;
      }
    }

    .job-box {
      width: 100%;
      height: max-content;
      position: relative;
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      margin-bottom: 20px;
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);

      .job-title {
        width: calc(100% - 50px);
        height: max-content;
        display: flex;
        flex-direction: column;

        p {
          font-size: 300;
          color: $grayl;
          margin-bottom: 5px;
        }

        h5 {
          color: $grayl;
          transition: 0.2s ease-in-out;
        }

        &:hover,
        &:focus {
          h5 {
            color: $bluel;
          }
        }
      }

      button {
        width: 50px;
        height: 50px;
        color: rgba($color: #000000, $alpha: 0.2);

        svg {
          height: 50%;
        }

        &:hover,
        &:focus {
          color: $yellowd;
        }
      }
    }

    .language-box {
      width: 100%;
      height: max-content;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
      margin-bottom: 20px;
    }

    .settings-box {
      width: 100%;
      height: max-content;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      border-top: solid 1px rgba($color: #000000, $alpha: 0.2);
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);

      .text-wrapper {
        height: max-content;
        width: calc(100% - 40px);
        position: relative;
        display: flex;
        flex-direction: column;

        p {
          font-weight: 300;

          &:nth-last-child(1) {
            margin-bottom: 0;
          }
        }
      }
    }

    .btn-50 {
      width: 50px;
      height: 50px;
      color: rgba($color: #000000, $alpha: 0.2);
      background: white;
      border: solid 1px rgba($color: #000000, $alpha: 0.2);
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      svg {
        height: 50%;
      }

      &:hover,
      &:focus {
        color: $yellowd;
      }
    }

    .sign-for-job-modal-body {
      p {
        font-weight: 300;
        margin-bottom: 20px;
      }

      button {
        width: 100%;
        margin-bottom: 20px;
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }

    @include mq('md') {
      .sides {
        width: 100%;
        margin-bottom: 30px;
      }
    }

    @include mq('sm-4') {
      .box-50 {
        width: 100%;
      }

      .w-half {
        width: 100%;
        margin-bottom: 10px;
      }

      .w-third {
        width: 48%;
      }

      .w-quater {
        order: 1;
        width: 48%;
        margin-bottom: 10px;
      }

      .language-box {
        .w-third {
          width: 100%;
        }
      }
    }
  }

  .alert-modal {
    .modal-header {
      h5 {
        display: block;
        width: 100%;
        text-align: center;
      }
    }

    .modal-body {
      h5 {
        display: block;
        width: 80%;
        margin: 0 auto 40px auto;
        color: $grayl;
        text-align: center;
        font-weight: normal;
      }
      p {
        text-align: center;
        &.questions-title {
          font-weight: 500;
          margin: 20px 0;
          width: 100%;
        }
      }
      .question-content-box {
        width: 100%;
        height: max-content;
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
        padding-bottom: 30px;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        button {
          width: 100%;
          margin-top: 10px;
        }
        p {
          &.question-number {
            text-transform: uppercase;
          }
          margin-bottom: 5px;
        }
      }
    }
  }
  .alert-body {
    flex-direction: column;
    button {
      width: 100%;
      margin-bottom: 15px;
      &.no-bg-danger {
        color: $yellowd;
        &:hover,
        &:focus {
          box-shadow: inset 0px 0px 15px $yellowd;
        }

        &:active {
          box-shadow: inset 0px 0px 25px $yellowd;
        }
      }
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
    p {
      margin-bottom: 20px;
    }
  }

  .s-modal {
    max-width: 360px;
  }

  .m-modal {
    max-width: 1000px;
  }

  .l-modal {
    max-width: 1190px;
  }

  &.show {
    display: flex;
  }

  .questions-wrapper {
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .radio-wrapper {
      margin-bottom: 20px;
    }
  }
}
