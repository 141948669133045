.container-calendar {
    background: white;
    width: 100%;
    height: max-content;
    border-radius: 20px;
    box-shadow: 0 5px 10px rgba($color: #000000, $alpha: 0.2);
    overflow: hidden;
}
.calendar-header{
    width: 100%;
    height: max-content;
    position: relative;
    padding: 20px;
    background: linear-gradient(to top, $blued, $bluel);
    h3{
        font-size: 18px;
        color: white;
        font-weight: 500;
        text-transform: uppercase;
        text-align: start !important;
        margin-bottom: 0;
    }
}

.button-container-calendar{
    width: max-content;
    height: max-content;
    position: absolute !important;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    button{
        height: 30px;
        width: 30px;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
            height: 15px;
        }
    }
    #previous{
        margin-right: 10px;
    }
}


.table-calendar {
    border-collapse: collapse;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    tr{
        width: 100%;
        height: max-content;
        position: relative;
        display: flex;
    }
    td{
        height: 0;
        width: calc(100% / 7);
        padding-bottom: calc(100% / 7);
        border-radius: 50%;
        position: relative;
        text-align: center;
        vertical-align: center;
        font-size: 16px;
        color: $grayl;
        cursor: pointer;
        span{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: 2;
            @include prefixer(pointer-events,none);
        }
        &::after{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            height: 90%;
            width: 90%;
            border-radius: 50%;
            transition: .2s ease-in-out;
            opacity: 0;
        }
        &.selected{
            span{
                font-weight: 500;
            }            
            color: $grayd;
            &::after{
                opacity: 1;
            }
        }
        &.selected-start{
            color: white;
            &::after {
                background: linear-gradient(to top, $blued, $bluel);
            }
        }
        &.selected-end{
            color: white;
            
            &::after {
                background: linear-gradient(to top, $yellowd, $yellowl);
            }
        }        
    }
    th{
        height: 0;
        width: calc(100% / 7);
        padding-bottom: calc(100% / 7);
        border-radius: 50%;
        position: relative;
        text-align: center;
        vertical-align: center;
        font-size: 16px;
        color: $grayl;

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 2;
            font-size: 300;
        }
    }
}