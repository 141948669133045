@import "../../../scss/colors";
@import "../../../scss/shadows";
@import "../../../scss/animations";
@import "../../../scss/breakpoints";

.cv-simplecard-main {
  width: 100%;
  border-radius: 20px;
  box-shadow: $shadow_1;
  background: linear-gradient(to bottom, #ffffff, #eff3f6);
  border: 1px solid rgba($black, 0.1);
  animation: animation2 300ms forwards ease-in-out;
  &.cv-simplecard-main-disable-animation {
    animation: none !important;
  }
}

.cv-simplecard-title {
  width: 100%;
  border-bottom: 1px solid rgba($black, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  height: 70px;
  .cv-simplecard-title-element {
    color: $secondary;
    font-size: 22px;
    font-weight: 500;
    text-transform: uppercase;
    @include md {
      font-size: 18px;
    }
  }
  @include xl {
    padding: 0px 15px;
    height: 60px;
  }
}

.cv-simplecard-actions {
  display: flex;
  align-items: center;
  & > * {
    &:not(:last-child) {
      margin-right: 15px;
    }
  }
}

.cv-simplecard-content {
  width: 100%;
  padding: 20px;
}
