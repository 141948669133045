.oglas-col {
  display: flex;
  align-items: center;
  @media screen and (max-width: 600px) {
    flex-direction: column !important;
  }
}

.oglas-col-img {
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
  overflow: hidden;
  background: #fff;
  padding: 5px;
  border: 1px solid #00000020;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
}

.oglas-col-text {
  padding-left: 8px;
  a,
  div {
    white-space: nowrap;
  }
}


@media screen and (max-width: 1200px) {

  .MuiTableRow-root {
    display: flex !important;
    flex-direction: column !important;
    position: relative !important;
    padding: 10px !important;
  }
  .MuiTableHead-root {
    display: none !important;
  }
  .MuiTableCell-root {
    border-bottom: none !important;
    height: auto !important;
    padding: 3px !important;
  }
  .MuiTableRow-root .MuiTableCell-root:not(:last-child) {
    border-right: none !important;
    margin-left: 0 !important;
  }


  // USERS

  .main-active-users {
    .MuiTableRow-root td.MuiTableCell-root:nth-child(1) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        top: 19px;
        left: 12px;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(2) {
      display: none !important;
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(3) {
      a {
        margin-left: 35px;
        margin-top: 5px;
        margin-bottom: 15px;
        display: block;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(4) {
      border-top: solid 1px rgba(0, 0, 0, 0.2);
      padding-top: 15px !important;
      a {
        font-weight: 500 !important;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(5) {
  
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(6) {
  
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(7) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiSwitch-root {
        position: absolute;
        top: 10px;
        right: 5px;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(8) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        bottom: 0px;
        right: 0;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(9) {
      display: none !important;
    }
  }

  // archived users

  .main-archived-users {
    .MuiTableRow-root td.MuiTableCell-root:nth-child(1) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        top: 19px;
        left: 12px;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(2) {
      display: none !important;
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(3) {
      a {
        margin-left: 35px;
        margin-top: 5px;
        margin-bottom: 15px;
        display: block;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(4) {
      border-top: solid 1px rgba(0, 0, 0, 0.2);
      padding-top: 15px !important;
      a {
        font-weight: 500 !important;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(5) {

    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(6) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiIconButton-root {
        position: absolute;
        top: 5px;
        right: 0px;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(7) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        bottom: 0px;
        right: 0;
      }
    }
  }

  // END


  // COMPANIES

  .main-active-companies {
    .MuiTableRow-root td.MuiTableCell-root:nth-child(1) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        top: 19px;
        left: 12px;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(2) {
      display: none !important;
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(3) {
      a {
        margin-left: 35px;
        margin-bottom: 15px;
        margin-top: 5px;
        display: block;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(4) {
      border-top: solid 1px rgba(0, 0, 0, 0.2);
      padding-top: 15px !important;
      a {
        font-weight: 500 !important;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(5) {
  
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(6) {
  
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(7) {
  
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(8) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiSwitch-root {
        position: absolute;
        top: 10px;
        right: 5px;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(9) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        bottom: 0px;
        right: 0;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(10) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiIconButton-root {
        position: absolute;
        bottom: 50px;
        right: 0;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(11) {
      display: none !important;
    }
  }

  // archived companies

  .main-archived-companies {
    .MuiTableRow-root td.MuiTableCell-root:nth-child(1) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        top: 19px;
        left: 12px;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(2) {
      display: none !important;
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(3) {
      a {
        margin-left: 35px;
        margin-top: 5px;
        margin-bottom: 15px;
        display: block;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(4) {
      border-top: solid 1px rgba(0, 0, 0, 0.2);
      padding-top: 15px !important;
      a {
        font-weight: 500 !important;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(5) {

    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(6) {

    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(7) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiIconButton-root {
        position: absolute;
        top: 5px;
        right: 0px;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(8) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        bottom: 0px;
        right: 0;
      }
    }
  }

  // END


  // ADS

  .main-active-ads {
    .MuiTableRow-root td.MuiTableCell-root:nth-child(1) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        top: 19px;
        left: 12px;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(2) {
      display: none !important;
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(3) {
      a {
        margin-left: 35px;
        margin-bottom: 15px;
        margin-top: 5px;
        display: block;
      }
      .oglas-col {
        align-items: flex-start;
      }
      .oglas-col-img {
        display: none !important;
      }
      .oglas-col-text {
        padding-left: 0 !important;
        width: 100% !important;
      }
      .oglas-col-text a {
        margin-left: 35px;
        margin-bottom: 15px;
      }
      .oglas-col-text div {
        margin-top: 15px;
        font-weight: 500;
        border-top: solid 1px rgba(0, 0, 0, 0.2);
        padding-top: 15px;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(4) {
      a {
        font-weight: 500 !important;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(5) {
  
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(6) {
  
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(7) {
      display: none !important;
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(8) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        bottom: 0px;
        right: 0;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(9) {
      display: none !important;
    }
  }

  // archived ads

  .main-archived-ads {
    .MuiTableRow-root td.MuiTableCell-root:nth-child(1) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        top: 19px;
        left: 12px;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(2) {
      display: none !important;
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(3) {
      a {
        margin-left: 35px;
        margin-top: 5px;
        margin-bottom: 15px;
        display: block;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(4) {
      border-top: solid 1px rgba(0, 0, 0, 0.2);
      padding-top: 15px !important;
      a {
        font-weight: 500 !important;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(5) {

    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(6) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiIconButton-root {
        position: absolute;
        top: 5px;
        right: 0px;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(7) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        bottom: 0px;
        right: 0;
      }
    }
  }

  // END


  // CANDIDATES

  .main-active-candidates {
    .MuiTableRow-root td.MuiTableCell-root:nth-child(1) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        top: 19px;
        left: 12px;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(2) {
      display: none !important;
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(3) {
      a {
        margin-left: 35px;
        margin-bottom: 15px;
        display: block;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(4) {
      display: none !important;
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(5) {
      border-top: solid 1px rgba(0, 0, 0, 0.2);
      padding-top: 15px !important;
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(6) {
  
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(7) {
  
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(8) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiIconButton-root {
        position: absolute;
        top: 5px;
        right: 0px;
      }
      // display: none !important;
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(9) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        bottom: 0px;
        right: 0;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(10) {
      // padding: 0 !important;
      // margin: 0 !important;
      // .MuiIconButton-root {
      //   position: absolute;
      //   bottom: 50px;
      //   right: 0;
      // }
      display: none !important;
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(11) {
      display: none !important;
    }
  }

  // archived candidates

  .main-archived-candidates {
    .MuiTableRow-root td.MuiTableCell-root:nth-child(1) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        top: 19px;
        left: 12px;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(2) {
      display: none !important;
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(3) {
      a {
        margin-left: 35px;
        margin-top: 5px;
        margin-bottom: 15px;
        display: block;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(4) {
      border-top: solid 1px rgba(0, 0, 0, 0.2);
      padding-top: 15px !important;
      a {
        font-weight: 500 !important;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(5) {

    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(6) {

    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(7) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiIconButton-root {
        position: absolute;
        top: 5px;
        right: 0px;
      }
    }
    .MuiTableRow-root td.MuiTableCell-root:nth-child(8) {
      padding: 0 !important;
      margin: 0 !important;
      .MuiButtonBase-root {
        position: absolute;
        bottom: 0px;
        right: 0;
      }
    }
  }

  // END

  

}
