// main
.logo-box {
  width: 240px;
  height: max-content;
  position: relative;
  margin-right: 20px;
  svg {
    width: 100%;
    height: auto;
  }

  @include mq('xl') {
    width: 200px;
  }
}
header {
  width: 100%;
  height: max-content;
  // padding: 30px 0;
  padding-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
  margin-bottom: 30px;
  @include mq('md') {
    padding-top: 0;
    flex-wrap: nowrap;
  }
}
.nav-wrapper {
  width: max-content;
  height: max-content;
  display: flex;
  @include mq('md') {
    display: none;
  }
}
.btn-wrapper {
  height: max-content;
  width: max-content;
  margin-left: 30px;
  position: relative;
  @include mq('sm') {
    .MuiButtonBase-root {
      width: 100% !important;
    }
    margin-left: 0;
    margin-right: 20px;
    &:nth-last-child(1) {
      margin-right: 0;
    }
  }
}

.candidate-bg-image-change {
  position: absolute !important;
  right: 10px;
  top: 10px;
  background: linear-gradient(to bottom, $bluel, $blued) !important;
  color: #fff !important;
  z-index: 10;
}
.candidate-image-holder {
  width: 160px;
  height: 160px;
  position: relative;
  pointer-events: all;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border: 2px solid #00000020;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
  }
  .candidate-image-change {
    position: absolute;
    right: 5px;
    bottom: 5px;
    background: linear-gradient(to bottom, $bluel, $blued) !important;
    color: #fff;
  }
}

.candidate-link {
  display: inline-flex;
  align-items: center;
}

.candidate-video {
  width: 100%;
  padding-top: 60%;
  border-radius: 10px;
  border: 2px solid $bluel;
  position: relative;
  overflow: hidden;
  background: #000;
  video {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
  img {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.candidate-main {
  display: block;
  padding: 20px;
  width: 100%;
}

.candidate-row {
  display: flex;
}

.candidate-profile-row {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  position: relative;
  pointer-events: none;
}

.add-ad-row-actions {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.candidate-profile-info {
  margin-left: 20px;
  padding: 30px 0px;
  border-bottom: 1px solid #00000020;
  border-top: 1px solid #00000020;
  width: calc(100% - 180px);
}

.ico-box {
  width: 50px;
  height: 50px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $bluel;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.1);
  svg {
    width: 45%;
    height: 45%;
  }
  &:hover,
  &:focus,
  &.active {
    background: $bluel;
    color: white;
  }
}
.btn-stats {
  position: absolute;
  height: 20px;
  width: 20px;
  top: 0;
  right: 0;
  background: linear-gradient(to top, $yellowd, $yellowl);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 10px;
  font-weight: normal;
  border-radius: 50%;
  transform: translate(10%, -10%);
  @include prefixer(pointer-events, none);
  @include prefixer(user-select, none);
}
.profile-wrapper {
  width: max-content;
  height: max-content;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 50px;
    width: 50px;
    object-fit: cover;
    object-position: center;
    border-radius: 50%;
    margin-right: 10px;
    border: solid 2px $bluel;
  }
  p {
    color: $grayl;
  }
  @include mq('md') {
    width: 210px;
    justify-content: flex-start;
  }
  @include mq('sm') {
    width: 100%;
    margin-top: 30px;
  }
}
.nav-btn-menu {
  height: max-content;
  width: max-content;
  display: flex;
  @include mq('md') {
    width: 100%;
    margin-top: 30px;
    justify-content: flex-end;
  }
  @include mq('sm') {
    justify-content: flex-start;
  }
}

// content
#mainContent {
  height: max-content;
  width: calc(100% - 300px);
  margin-left: 60px;
  position: relative;
  display: flex;
  flex-wrap: wrap;

  header {
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 20px;
    padding: 0;
    margin-bottom: 0;
    border: none;

    .title-box {
      width: max-content;
      height: max-content;
      display: flex;
      flex-direction: column;
      margin-right: 20px;
      margin-bottom: 30px;
    }

    .btn-wrapper {
      height: max-content;
      width: max-content;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-left: 0;
      position: relative;
      button {
        margin-left: 20px;
      }
      @include mq('xl-14') {
        width: 100%;
        button {
          margin-left: 0;
          margin-right: 20px;
          flex-wrap: wrap;
          margin-bottom: 20px;
        }
      }
      @include mq('md') {
        align-items: center;
        justify-content: space-between;
        margin-right: 0;
        button {
          width: 48%;
          margin-right: 0;
        }
      }
      @include mq('sm-4') {
        align-items: center;
        justify-content: space-between;

        button {
          width: 100%;
        }
      }
    }

    button {
      margin-bottom: 30px;
    }

    @include mq('sm') {
      button {
        width: max-content;
      }
      .title-box {
        width: 100%;
        margin-right: 0;
      }
    }
    @include mq('sm-4') {
      .btn-order-sm-last {
        width: 100%;
        order: 6;
      }
    }
  }
  .search-box-wrapper {
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    .select-wrapper {
      width: 320px;
    }
    .wrapper {
      display: flex;
      align-items: center;
    }
    .search-wrapper {
      width: max-content;
      height: max-content;
      position: relative;
      .input-class {
        width: 400px;
        padding-right: 50px;
        margin-bottom: 0;
      }
      .search {
        position: absolute;
        top: 0;
        right: 0;
        height: 50px;
        width: 50px;
        // color: rgba($color: #000000, $alpha: .2);
        color: $grayl;
        svg {
          height: 40%;
        }
        &:hover,
        &:focus {
          color: $bluel;
        }
      }
    }
    .settings-btn {
      height: 50px;
      width: 50px;
      border-radius: 7px;
      margin-left: 10px;
      background: white;
      box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
      color: $bluel;
      margin-bottom: 0;
      position: relative;
      &:hover,
      &:focus {
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.1);
      }
      &:active {
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0);
      }
      svg {
        height: 40%;
        position: absolute;
        top: 50%;
        right: 49%;
        transform: translate(50%, -50%);
      }
    }
    @include mq('xl-14') {
      .search-wrapper {
        /*input {
          width: 350px;
        }*/
      }
    }
    @include mq('xl') {
      .wrapper {
        width: 100%;
        margin-bottom: 20px;
        order: -1;
      }
      .search-wrapper {
        width: calc(100% - 60px);
        /*input {
          width: 100%;
        }*/
      }
    }
    @include mq('sm-4') {
      .wrapper {
        margin-bottom: 0;
      }
      .select-wrapper {
        display: none;
      }
    }
  }

  // info
  .info-display {
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    background: linear-gradient(to top, $bg, white);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);

    p {
      color: $grayl;
      margin-bottom: 5px;
      span {
        color: $redl;
      }
    }
    .title {
      width: 100%;
      height: max-content;
      display: block;
      padding: 20px;
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
    }
    /*input {
      background: white;
      text-align: left;
      padding: 15px;
      margin-bottom: 0;
    }*/
    .select {
      background: white;
    }

    .info-wrapper {
      width: 100%;
      height: max-content;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 20px;
    }
    .info-box {
      width: 30%;
      height: max-content;
      display: flex;
      flex-direction: column;
      padding: 30px 0;
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
    }
    .info-box-small {
      width: 22%;
      border-bottom: none;
    }
    .info-box-big {
      width: 48%;
      border-bottom: none;
    }

    @include mq('xl-14') {
      .info-box-small {
        width: 30%;
      }
      .info-box-big {
        width: 65%;
      }
    }
    @include mq('xl') {
      .info-box {
        width: 48%;
      }
      .info-box-big {
        width: 100%;
      }
    }
    @include mq('sm') {
      .info-box {
        width: 100%;
        padding: 15px 0;
      }
    }
  }

  .date-of-birth-box {
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .select-wrapper {
      width: 25%;
      &.w-third {
        width: 31% !important;
      }
      &:nth-last-child(1) {
        width: 40%;
      }
    }
  }

  .fill-status {
    width: 48%;
    height: max-content;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0;

    p {
      width: calc(100% - 170px);
      text-align: start;
    }

    .counter-wrapper {
      width: max-content;
      height: max-content;
      position: relative;
      z-index: 3;

      .precent {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include func(font-size, 48, 24);
        font-weight: 500;
        color: $bluel;
      }

      #counterBg {
        position: absolute;
        top: 0;
        right: 0;
        z-index: -1;
      }
    }

    @include mq('xl-16') {
      p {
        width: 100%;
      }
    }
    @include mq('xl-14') {
      width: 100%;
      p {
        width: calc(100% - 170px);
      }
    }
    @include mq('lg') {
      width: 48%;
    }
    @include mq('md') {
      width: 100%;
    }
    @include mq('sm') {
      align-items: center;
      justify-content: center;
      p {
        width: 100%;
        text-align: center;
      }
    }
  }

  .position-btn-end {
    height: auto !important;
    display: flex;
    align-items: flex-end;
    @include mq('sm') {
      margin-bottom: 0 !important;
    }
  }

  // cv
  .img-side {
    width: 100%;
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
    .img-placeholder {
      height: 175px;
      width: 175px;
      background: rgba($color: #000000, $alpha: 0.2);
      position: relative;
      margin-right: 20px;
      .img-holder {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
      }
      img {
        display: none;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }
      .add-img-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 50px;
        width: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          height: 20px;
        }
      }
    }
    .is-filled {
      background: transparent;
      img {
        display: block;
      }
      button {
        top: initial !important;
        left: initial !important;
        transform: initial !important;
        right: 0;
        bottom: 0;
        background: linear-gradient(to top, $blued, $bluel);
        &:hover,
        &:focus {
          box-shadow: inset 0px 0px 15px rgba($color: $bluel, $alpha: 1);
        }
        &:active {
          box-shadow: inset 0px 0px 15px rgba($color: $blued, $alpha: 1);
        }
      }
    }
    .img-added {
      .add-img-btn {
        position: absolute;
        top: initial;
        left: initial;
        transform: initial;
        bottom: 0;
        right: 0;
      }
    }
    @include mq('xl-14') {
      .img-placeholder {
        width: 150px;
        height: 150px;
      }
    }
    @include mq('sm') {
      flex-direction: column;
      .img-placeholder {
        margin-right: 0;
        margin-bottom: 20px;
      }
      .name-wrapper {
        width: 100%;
        align-items: center;

        h3 {
          width: 100%;
          text-align: center;
        }
        p {
          text-align: center;
        }
      }
    }
  }

  .name-wrapper {
    height: max-content;
    width: max-content;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    .name-active {
      color: $bluel;
    }
    h3 {
      @include func(font-size, 30, 17);
      line-height: 1.1em;
      margin-bottom: 10px;
      width: min-content;
    }

    p {
      font-size: 15px;
      font-weight: 300;
      color: $grayl;

      &:nth-child(1) {
        margin-bottom: 7px;
      }
      span {
        color: $yellowd;
      }
    }
  }

  .profile-fill-box {
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    p {
      margin-bottom: 0;
    }
    .name-wrapper {
      height: max-content;
      width: max-content;
      max-width: calc(100% - 35px);
      display: flex;
      flex-direction: column;
      h3 {
        @include func(font-size, 30, 17);
        line-height: 1.1em;
        margin-bottom: 10px;
        width: min-content;
      }

      p {
        font-size: 15px;
        font-weight: 300;
        color: $grayl;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        &:nth-child(1) {
          margin-bottom: 7px;
        }
        span {
          color: $yellowd;
        }
      }
    }
    .fill-box {
      width: 48%;
      height: max-content;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0;
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
      &.w-100 {
        width: 100%;
      }
    }
    .fill-box-header {
      width: 100%;
      padding: 30px 0;
      border-top: solid 1px rgba($color: #000000, $alpha: 0.2);
    }
    .add-info {
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba($color: #000000, $alpha: 0.2);
      &:hover,
      &:focus {
        color: $bluel;
      }
      &:active {
        color: $blued;
      }
      svg {
        height: 80%;
      }
    }
    .edit-info {
      height: 30px;
      width: 30px;
      display: none;
      align-items: center;
      justify-content: center;
      color: rgba($color: #000000, $alpha: 0.2);
      &:hover,
      &:focus {
        color: $bluel;
      }
      &:active {
        color: $blued;
      }
      svg {
        height: 80%;
      }
    }
    .is-filled {
      .add-info {
        display: none;
      }
      .edit-info {
        display: flex;
      }
    }
    @include mq('xl-14') {
      .fill-box {
        width: 100%;
      }
    }
    @include mq('xl') {
      width: 100%;
    }
    @include mq('sm') {
      .fill-box {
        width: 100% !important;
      }
      .all-btns {
        width: 100%;
      }
    }
  }

  // cv video
  .video-side {
    width: 55%;
    height: max-content;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5%;
    .video-empty-box {
      width: 100%;
      height: max-content;
      position: relative;
    }
    .video-added-box {
      width: 100%;
      height: max-content;
      position: relative;
    }
    .video-placeholder {
      width: 100%;
      height: 0;
      padding-bottom: 56.25%;
      background: rgba($color: #000000, $alpha: 0.2);
      border-radius: 10px;
      object-fit: contain;
    }
    .video-wrapper-box {
      width: 100%;
      height: max-content;
      position: relative;
    }
    video {
      width: 100%;
      height: auto;
      border-radius: 10px;
      &[poster] {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        filter: brightness(0.5);
      }
    }
    .play-video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      z-index: 3;
      height: 50px;
      width: auto;
      svg {
        height: 100%;
      }
      &:hover,
      &:focus {
        color: $yellowl;
      }
      &:active {
        color: $yellowd;
      }
    }
    .download-btn {
      height: max-content;
      color: $grayl;
      justify-content: flex-start;
      margin-top: 20px;
      padding-left: 0;
      &:hover,
      &:focus {
        color: $bluel;
      }
    }
  }
  .video-info-side {
    width: 40%;
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    p {
      color: $grayl;
      margin-bottom: 20px;
    }
    button {
      margin-top: 20px;
    }
  }
  .info-empty-box {
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
  }
  .info-video-box {
  }
  .is-filled {
    .video-empty-box {
      display: none;
    }
    .video-added-box {
      display: flex !important;
      flex-direction: column;
    }
    .play-video {
      svg {
        path {
          fill: url('#yellowGradient');
        }
      }
    }
    .info-empty-box {
      display: none;
    }
    .info-video-box {
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      h2 {
        text-transform: initial;
        padding-bottom: 20px;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        margin-bottom: 20px;
      }
      .video-details-box {
        width: 100%;
        height: max-content;
        position: relative;
        display: flex;
        flex-direction: column;
        padding-bottom: 20px;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        margin-bottom: 20px;
        p {
          margin-bottom: 5px;
        }
      }
    }
  }

  // pagination
  .pagination-box {
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    margin-top: 40px;
    button {
      height: 40px;
      width: 40px;
      border-radius: 10px;
      font-size: 15px;
      font-weight: 300;
      border: solid 1px rgba($color: #000000, $alpha: 0.2);
      color: $grayl;
      box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0);
      margin-left: 10px;
      margin-bottom: 10px;
      svg {
        height: 50%;
      }

      &:hover,
      &:focus,
      &.active {
        color: $bluel;
        border-color: transparent;
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
      }
      &:active {
        color: $grayl;
        border-color: rgba($color: #000000, $alpha: 0.2);
        box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0);
      }
    }
  }

  // podesavanja
  .input-text {
    margin-bottom: 0;
    &:disabled {
      border: none;
      padding: 0;
      height: max-content;
      background: transparent;
      border-radius: initial;
    }
  }

  /*.input-width {
    width: calc(100% - 50px) !important;
  }*/

  @include mq('xl-16') {
    .ceil {
      height: auto !important;
      padding: 5px !important;
    }
    .last-ceil {
      padding: 0 !important;
      .sub-ceil {
        // height: max-content !important;
        height: 50%;
        &:nth-child(1) {
          width: 100%;
          border-right: none;
          border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
          p {
            text-align: center;
          }
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: 50%;
        }
      }
      .sub-ceil-quarter {
        height: 50% !important;
        width: 50% !important;
        border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
        &:nth-child(2n) {
          border-right: none;
        }
        &:nth-child(3),
        &:nth-child(4) {
          border-bottom: none;
        }
      }
    }
  }
  @include mq('xl-14') {
    width: calc(100% - 240px);
    margin-left: 40px;
  }
  @include mq('xl') {
    &.xl-resize {
      width: 100%;
      margin-left: 0;
    }
  }
  @include mq('lg') {
    width: 100%;
    margin-left: 0;
    .concourse-table {
      display: none !important;
    }
    .concourse-table-lg {
      display: initial;
    }
  }
  @include mq('md') {
    .video-side {
      width: 100%;
      margin-bottom: 30px;
      margin-right: 0;
    }
    .video-info-side {
      width: 100%;
    }
  }
}

// oglasi detalji
.cv-details-box {
  width: 100%;
  height: max-content;
  position: relative;
  display: flex;
  flex-direction: column;
  background: linear-gradient(to top, $bg, white);
  border-radius: 10px;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
}
.cv-header {
  width: 100%;
  height: max-content;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
  h2 {
    text-transform: initial;
  }
}
.cv-body {
  width: 100%;
  height: max-content;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;

  .questions-ad {
    border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
  }

  .btn-box-ad {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .company-details {
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: row;
    border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);

    .video-presentation {
      position: absolute;
      right: 0;
      bottom: 50%;
    }

    .company-logo-box {
      width: 150px;
      height: 150px;
      background: white;
      border-radius: 50%;
      border: 1px solid rgba($color: #000000, $alpha: 0.2);
      padding: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      margin-bottom: 30px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
    .details {
      list-style: none;
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
    ol {
      padding-bottom: 30px;
      margin-bottom: 30px;
    }
    li {
      @include func(font-size, 16, 14);
      font-weight: 300;
      color: $grayl;
      margin-bottom: 10px;
    }
    p {
      margin-bottom: 10px;
    }
    button {
      width: max-content;
      max-width: 100%;
      padding: 10px 30px;
    }
    @include mq('xl-14') {
      button {
        padding: 10px 15px;
      }
    }
    @include mq('md') {
      margin-top: 30px;
    }
  }
  .company-description {
    border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);

    h5 {
      color: $grayl;
      margin-bottom: 40px;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: space-between;
      span {
        font-size: inherit !important;
      }
    }
    p {
      font-weight: 300;
      margin-bottom: 20px;
    }
    @media only screen and (min-width: 1920px) {
      ul li {
        font-size: 16px;
      }
    }

    ul li {
      font-weight: 300;
      font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1920 - 320)));
      list-style: outside;
      margin-left: 20px;
    }
  }
  .btns-box {
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    order: 3;
    margin-top: 40px;
    button {
      margin-bottom: 20px;
    }
  }
  @include mq('md') {
    button {
      width: 50% !important;
    }
    .company-details {
      width: 100%;
      order: 2;
    }
    .company-description {
      width: 100%;
    }
  }
  @include mq('sm') {
    button {
      width: 100% !important;
    }
  }
}
.cv-active {
  position: relative;
  font-weight: 500;
  color: $grayl;
  margin-left: 15px;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -5px;
    transform: translate(-100%, -50%);
    height: 10px;
    width: 10px;
    background: linear-gradient(to top, $blued, $bluel);
    border-radius: 50%;
  }
}

.mobile-nav-container {
  display: none;
  @include func(width, 1800, 300);
  a {
    margin: 0;
    svg {
      margin: 0;
    }
  }
  @include mq('md') {
    height: max-content;
    margin: 0 auto;
    padding: 0 15px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
}
.btn-profile-wrapper {
  width: 100%;
  height: max-content;
  position: relative;
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  button {
    margin-left: 20px;
  }
  .separator {
    width: 100%;
    height: 1px;
    background: rgba($color: #000000, $alpha: 0.2);
    margin: 30px 0;
  }
  @include mq('md') {
    button {
      width: 48%;
      margin-left: 2%;
    }
  }
  @include mq('sm') {
    button {
      width: 100%;
      margin-left: 0;
      margin-bottom: 20px;
    }
    .separator {
      margin-top: 10px;
    }
  }
}

.popup-box {
  position: absolute;
  width: 90vw;
  max-width: 400px;
  height: max-content;
  background: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
  display: none;
  flex-direction: column;
  top: 0;
  left: -40px;
  transform: translateX(-100%);
  opacity: 0;
  transition: 0.2s ease-in-out;
  z-index: 4;

  &::before {
    content: '';
    clip-path: polygon(0 1%, 0% 100%, 100% 50%);
    height: 20px;
    width: 20px;
    background: white;
    position: absolute;
    top: 15px;
    right: -20px;
  }
  &.show {
    display: flex;
    opacity: 1;
    z-index: 6;
  }

  .popup-header {
    padding: 15px;
    position: relative;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);

    h5 {
      font-size: 15px;
      font-weight: 500;
      color: $bluel;
      text-transform: uppercase;
      word-break: break-all;
      padding-right: 10px;
    }
  }
  .settings {
    width: 20px;
    height: 20px;
    color: rgba($color: #000000, $alpha: 0.2);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 15px;
    }

    &:hover,
    &:focus {
      color: $bluel;
    }
  }
  .popup-body {
    padding: 15px;
    position: relative;
    width: 100%;
    height: 350px;
    max-height: 400px;
    overflow: auto;
    display: flex;
    flex-direction: column;

    .notification {
      width: 100%;
      padding-left: 20px;
      height: max-content;
      margin-bottom: 20px;
      text-align: start;
      cursor: pointer;
      position: relative;

      p {
        font-size: 14px;
        color: $grayl;
        font-weight: 300;
        margin-bottom: 0;
        transition: 0.2s ease-in-out;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;

        &.title {
          font-weight: 500;
        }
      }

      &:hover,
      &:focus {
        p {
          &.title {
            color: $bluel;
          }
        }
      }
    }

    .unread {
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 10px;
        width: 10px;
        border-radius: 50%;
        background: linear-gradient(to top, $yellowd, $yellowl);
      }
    }

    .load-all-wrapper {
      width: 100%;
      height: max-content;
      padding: 15px;
      background-color: white;
      border-radius: 0 0 10px 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      @include mq('md') {
        padding-bottom: 0;
      }
    }
    .load-all-btn {
      width: 100%;
    }
  }

  .close-box {
    height: 30px;
    width: 30px;
    color: rgba($color: #000000, $alpha: 0.2);
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      height: 25px;
    }
    &:hover,
    &:focus {
      color: $bluel;
    }
  }

  @include mq('md') {
    position: fixed;
    top: 0;
    left: 0;
    transform: initial;
    width: 100%;
    max-width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.5);
    backdrop-filter: blur(10px);
    z-index: 999;
    flex-direction: initial;
    align-items: center;
    justify-content: center;

    &::before {
      display: none;
    }

    .popup-container {
      height: max-content;
      @include func(width, 1800, 300);
      position: relative;
      background: linear-gradient(to top, white, $bg);
      border-radius: 10px;
      box-shadow: 0 0 10px rgba($color: #000000, $alpha: 0.2);
      padding: 30px;
      .popup-body {
        max-height: calc(90vh - 110px);
      }
    }
    .popup-header {
      padding: 20px 0;
    }
    .popup-body {
      padding: 20px 0;
    }
  }
}

.rec-box-img {
  width: 100%;
  height: 40vh;
  background: $grayl;
  border-radius: 10px;
  position: relative;
}
.rec-box {
  width: 100%;
  height: 60vh;
  background: $grayl;
  border-radius: 10px;
  position: relative;

  /*iframe {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }*/

  button {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 25%;
    max-height: 100px;
    min-height: 40px;
    width: max-content;
    position: relative;
    svg {
      height: 100%;
    }
    &:hover,
    &:focus {
      transform: translate(-50%, -50%) scale(1.1);
    }
    &:active {
      transform: translate(-50%, -50%) scale(1);
    }
    z-index: 3;
  }
}
.video-btns-box {
  width: 100%;
  height: max-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 30px;
  @include mq('md') {
    button {
      width: max-content;
    }
  }
  @include mq('sm') {
    button {
      width: 100%;
      margin-bottom: 20px;
    }
  }
}

button {
  &.hvr-red {
    &:hover,
    &:focus {
      color: $yellowd !important;
      svg {
        color: $yellowd !important;
      }
    }
  }
  &.btn-40 {
    width: 40px !important;
    height: 40px !important;
  }
}

.settings-toggle-wrapper {
  height: max-content;
  width: 40px;
  position: relative;
}

.settings-toggle {
  height: 25px;
  width: 40px;
  @include prefixer(appearance, none);
  border-radius: 15px;
  background: linear-gradient(to top, $blued, $bluel);
  outline: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  filter: grayscale(1);
  transition: 0.2s ease-in-out;
  &::before {
    content: '';
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background-color: white;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    transition: 0.2s ease-in-out;
  }

  &:checked {
    filter: grayscale(0);
    &::before {
      left: 20px;
    }
  }
}
