nav {
  width: 240px;
  height: max-content;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  position: relative;

  details {
    width: 100%;
    overflow: hidden;
    border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
    transition: 0.2s ease-in-out;

    summary {
      width: 100%;
      height: 70px;
      color: $blued;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 20px;
      position: relative;

      svg {
        width: 20px;
        height: auto;
        margin-right: 20px;
        top: initial;
        right: initial;
        position: relative;
        transform: none;
      }

      p {
        font-size: 17px;
        font-weight: 500;
        color: inherit;
        display: inline-block;
        width: calc(100% - 40px);
        text-align: left;
        word-break: break-all;
      }

      &::-webkit-details-marker {
        display: none;
      }

      &:before {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(to top, $blued, $bluel);
        border-radius: 10px;
        z-index: -1;
        transition: 0.2s ease-in-out;
        opacity: 0;
      }
    }

    &.modal-details {
      border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);

      p {
        margin-bottom: 5px;
      }
      .detail-box {
        width: 100%;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
      }
    }

    &[open] {
      // padding-bottom: 20px;
      padding-bottom: 15px;
      margin-top: -1px;
      border-top: 1px solid #f1f4f7;
      z-index: 2;
      summary {
        color: white;
        &:before {
          opacity: 1;
        }
        svg {
          transform: none;
          fill: white;
        }
      }
    }

    &:hover,
    &:focus,
    &.active {
      summary {
        color: white;
        &:before {
          opacity: 1;
        }
        svg {
          fill: white;
        }
      }
    }

    @include mq("xl") {
      width: 31%;
      summary {
        height: 50px;
      }
    }
    @include mq("md") {
      width: 100%;
      summary {
        height: 70px;
      }
      .submenu {
        font-size: 17px;
        padding: 15px 0 !important;
      }
    }
  }

  a {
    width: 100%;
    height: 70px;
    border-bottom: solid 1px rgba($color: #000000, $alpha: 0.2);
    color: $blued;
    display: flex;
    align-items: center;
    padding: 20px;
    position: relative;

    svg {
      width: 20px;
      height: auto;
      margin-right: 20px;
    }

    p {
      font-size: 17px;
      font-weight: 500;
      color: inherit;
      display: inline-block;
      width: calc(100% - 40px);
      text-align: left;
      word-break: break-all;
    }

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(to top, $blued, $bluel);
      border-radius: 10px;
      z-index: -1;
      transition: 0.2s ease-in-out;
      opacity: 0;
    }

    &:hover,
    &:focus,
    &.active {
      border-color: transparent;
      color: white;

      &:before {
        opacity: 1;
      }
    }
    &.active {
      margin-top: -1px;
      border-top: 1px solid #f1f4f7;
    }

    &.logout {
      display: none;
    }
  }
  a[aria-current="page"] {
    margin-top: -1px;
    border-top: 1px solid #f1f4f7;
    z-index: 2;
  }
  .sublist {
    width: 100%;
    height: max-content;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-left: 60px;
    padding-top: 15px;
    .submenu {
      height: max-content;
      width: 100%;
      color: $grayl;
      font-weight: 300;
      background: transparent;
      padding: 10px 0;
      border-bottom: none;
      transition: 0.2s ease-in-out;
      &::before {
        content: "";
        background: transparent;
      }
      &.active {
        font-weight: 500;
      }
      &:hover,
      &:focus {
        color: $bluel;
      }
    }
  }

  .profile-mobile {
    display: none;
  }

  @include mq("xl-14") {
    width: 200px;
  }
  @include mq("xl") {
    &.xl-resize {
      width: 100%;
      gap: 30px;
      margin-bottom: 40px;

      a {
        height: 50px;
        width: 30%;
      }
    }
  }
  @include mq("lg") {
    width: 100%;
    gap: 30px;
    margin-bottom: 40px;

    a {
      height: 50px;
      width: 30%;
    }
  }
  @include mq("md") {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 0;
    width: 100%;
    background: $bg;
    opacity: 0;
    transition: 0.2s ease-in-out;
    z-index: 9;
    overflow: hidden;
    margin-bottom: 0;
    gap: 0;

    a {
      width: 100%;
      height: 70px;

      &.logout {
        display: flex;
      }

      p {
        font-size: 17px;
      }
    }

    &.xl-resize {
      gap: 0;

      a {
        width: 100%;
        height: 70px;

        &.profile-mobile {
          width: 100%;
          height: max-content;
        }
      }
    }

    .profile-mobile {
      width: 100%;
      height: max-content;
      display: flex;
      align-items: center;
      padding: 0 0 20px 0;
      border-bottom: none;

      img {
        height: 70px;
        width: 70px;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        margin-right: 10px;
        border: solid 2px transparent;
        transition: 0.2s ease-in-out;
      }

      p {
        color: $grayl;
        transition: 0.2s ease-in-out;
      }

      &::before {
        display: none;
      }

      &:hover,
      &:focus,
      &.active {
        img {
          border-color: $bluel;
        }

        p {
          color: $bluel;
        }
      }
    }

    &.open-menu {
      height: max-content;
      opacity: 1;
      margin-bottom: 40px;
    }
  }
}

#close-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4;
  display: none;
  &.show {
    display: block;
  }
}
