@import "../../../scss/colors";

.cv-data-display-main {
  width: 100%;
  border-bottom: 1px solid rgba($black, 0.1);
  padding: 10px 0px;
  overflow: hidden;
  .cv-data-display-label {
    color: rgba($black, 0.6);
    margin-bottom: 10px;
    font-size: 15px;
    span {
      color: $primary_second;
      margin-left: 5px;
    }
  }
  .cv-data-display-value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & > span {
      font-size: 16px;
      color: $black;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.cv-data-display-icon {
  width: 25px;
  height: 25px;
  margin-top: -20px;
}

.cv-data-display-value-full-width {
  width: 100%;
  span {
    width: 100%;
  }
}
